import { supabase } from '../lib/supabase';
import { UploadFile } from '../lib/s3client';

const bucketName = "panoramas";
const folderName = "uploads";
const baseUrl = `fsn1.your-objectstorage.com/${bucketName}/${folderName}`;

export const getThumbnailUrl = (imageName) => `https://${baseUrl}/thumbnails/${imageName}`;
export const getFullSizeUrl = (imageName) => `https://${baseUrl}/${imageName}`;

export const uploadImageAndUpdateSupabase = async (file, projectId, tableName) => {
  try {
    if (!bucketName) {
      throw new Error('REACT_APP_S3_BUCKET_NAME is not defined in environment variables');
    }

    // Step 1: Upload file to Hetzner Storage Box
    console.log("Uploading file to Hetzner Storage Box...");
    const imageUrl = await UploadFile(file, `${bucketName}/${folderName}`);

    // Construct the full URL
    const fullImageUrl = getFullSizeUrl(file.name);

    console.log("File uploaded successfully. Image URL:", fullImageUrl);

    // Step 2: Update Supabase with the image URL
    const { data, error } = await supabase
      .from(tableName)
      .insert({ 
        project_id: projectId,
        image_url: fullImageUrl,
        name: file.name
      })
      .select()
      .single();

    if (error) {
      throw new Error(`Error updating Supabase: ${error.message}`);
    }

    console.log("Image URL stored successfully in Supabase!");
    return { imageUrl: fullImageUrl, data };

  } catch (error) {
    console.error("Error in uploadImageAndUpdateSupabase:", error);
    throw error;
  }
};

// Optional: Add a function to fetch image URL from Supabase
export const getImageUrlFromSupabase = async (recordId, tableName) => {
  try {
    const { data, error } = await supabase
      .from(tableName)
      .select('image_url')
      .eq('id', recordId)
      .single();

    if (error) {
      throw new Error(`Error fetching image URL from Supabase: ${error.message}`);
    }

    return data.image_url;

  } catch (error) {
    console.error("Error in getImageUrlFromSupabase:", error);
    throw error;
  }
};
