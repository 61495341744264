import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import signInImage from '../assets/signin-image.jpg';  // We'll use the same image for now

const Logo = () => (
  <div className="flex justify-center mb-12">
    <img src="/panoramas/logo.svg" alt="Logo" style={{ filter: 'brightness(0)' }} className="w-52 h-auto" />
  </div>
);

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [retryDelay, setRetryDelay] = useState(1000); // 1 second
  const [lastAttempt, setLastAttempt] = useState(0);
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);  // Make sure this line is present

  const handleSubmit = async (e) => {
    e.preventDefault();
    const now = Date.now();
    if (now - lastAttempt < retryDelay) {
      setError(`Please wait ${Math.ceil((retryDelay - (now - lastAttempt)) / 1000)} seconds before trying again.`);
      return;
    }

    setError('');
    setSuccess('');
    setIsLoading(true);
    setLastAttempt(now);

    try {
      await signUp(email, password, fullName);
      setSuccess('Sign up successful! Please check your email for confirmation.');
      setRetryDelay(1000); // Reset delay on success
    } catch (error) {
      if (error.status === 429) {
        setRetryDelay(Math.min(retryDelay * 2, 60000)); // Exponential backoff
        setError(`Too many sign-up attempts. Please try again in ${Math.ceil(retryDelay / 1000)} seconds.`);
      } else {
        setError(error.message || 'An error occurred during sign up.');
        setRetryDelay(1000); // Reset delay for other errors
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (error && error.includes('Please wait')) {
      timer = setInterval(() => {
        const now = Date.now();
        if (now - lastAttempt >= retryDelay) {
          setError('');
        } else {
          setError(`Please wait ${Math.ceil((retryDelay - (now - lastAttempt)) / 1000)} seconds before trying again.`);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [error, lastAttempt, retryDelay]);

  return (
    <div className="flex h-screen relative">
      {/* Left Pane with Full-size Image */}
      <div className="hidden lg:block w-1/2 relative">
        <img 
          src={signInImage}  // Changed from signUpImage to signInImage
          alt="Sign Up" 
          className="absolute inset-0 w-full h-full object-cover"
        />
      </div>
      
      {/* Right Pane */}
      <div className="w-full bg-yellow-400 lg:w-1/2 flex items-center justify-center">
        <div className="max-w-md w-full p-12">
          <form onSubmit={handleSubmit} className="flex flex-col w-full h-full pb-6 text-center bg-yellow-400 rounded-3xl">
            
            <Logo />
            
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Full Name"
              className="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-300 mb-7 placeholder:text-grey-700 bg-white text-dark-grey-900 rounded-2xl"
              required
            />

            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-300 mb-7 placeholder:text-grey-700 bg-white text-dark-grey-900 rounded-2xl"
              required
            />

            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="flex items-center w-full px-5 py-4 mb-8 mr-2 text-sm font-medium outline-none focus:bg-grey-300 placeholder:text-grey-700 bg-white text-dark-grey-900 rounded-2xl"
              required
            />

            <button 
              type="submit"
              className="w-full px-6 py-5 mb-5 text-sm font-bold leading-none text-white transition duration-300 rounded-2xl hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 bg-black"
              disabled={isLoading}
            >
              {isLoading ? 'Signing Up...' : 'Sign Up'}
            </button>

            {error && <p className="text-red-500 mb-4">{error}</p>}
            {success && <p className="text-green-500 mb-4">{success}</p>}

            <p className="text-sm leading-relaxed text-grey-900">
              Already have an account? <Link to="/signin" className="font-bold text-grey-700 hover:underline">Sign In</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
