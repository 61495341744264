import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import signInImage from '../assets/signin-image.jpg';

const Logo = () => (
  <div className="flex justify-center mb-12"> {/* Increased bottom margin for better spacing */}
    <img src="/panoramas/logo.svg" alt="Logo" style={{ filter: 'brightness(0)' }} className="w-52 h-auto" /> {/* Increased width, maintained aspect ratio */}
  </div>
);

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const { login, googleLogin, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleLogin();
      // The user will be redirected to Google for authentication
    } catch (error) {
      console.error('Error during Google login:', error);
      setError('Failed to log in with Google. Please try again.');
    }
  };

  return (
    <div className="flex h-screen relative">
      {/* Left Pane with Full-size Image */}
      <div className="hidden lg:block w-1/2 relative">
        <img 
          src={signInImage} 
          alt="Sign In" 
          className="absolute inset-0 w-full h-full object-cover"
        />
      </div>
      
      {/* Right Pane */}
      <div className="w-full bg-yellow-400 lg:w-1/2 flex items-center justify-center">
        <div className="max-w-md w-full p-12">
          <form onSubmit={handleSubmit} className="flex flex-col w-full h-full pb-6 text-center bg-yellow-400 rounded-3xl">
            
            {/* Centered Logo added here, just before the "Sign In" text */}
            <Logo />
            {/*<h3 className="mb-8 text-2xl font-extrabold text-dark-grey-900">Sign In</h3>*/}
            
            {/*<label htmlFor="email" className="mb-2 text-sm text-start text-grey-900">Email*</label>*/}
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="mail@example.com"
              className="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-300 mb-7 placeholder:text-grey-700 bg-white text-dark-grey-900 rounded-2xl"
              required
            />

            {/*<label htmlFor="password" className="mb-2 text-sm text-start text-grey-900">Password*</label>*/}
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter a password"
              className="flex items-center w-full px-5 py-4 mb-4 mr-2 text-sm font-medium outline-none focus:bg-grey-300 placeholder:text-grey-700 bg-white text-dark-grey-900 rounded-2xl"
              required
            />

            {/* Remember Me and Forgot Password */}
            <div className="flex justify-between items-center mb-8">
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className="sr-only" // This hides the input visually but keeps it accessible
                  />
                  <div className={`w-5 h-5 rounded-sm flex items-center justify-center ${rememberMe ? 'bg-black' : 'bg-white'}`}>
                    {rememberMe && (
                      <svg className="w-3 h-3 text-white fill-current" viewBox="0 0 20 20">
                        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                      </svg>
                    )}
                  </div>
                </div>
                <span className="ml-2 text-sm text-grey-900">Keep me logged in</span>
              </label>
              <Link to="/forgot-password" className="text-sm text-grey-900 hover:underline">
                Forgot password?
              </Link>
            </div>

            <button 
              type="submit"
              className="w-full px-6 py-5 mb-5 text-sm font-bold leading-none text-white transition duration-300 rounded-2xl hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 bg-black"
            >
              Sign In
            </button>

            {error && <p className="text-red-500 mb-4">{error}</p>}

            <p className="text-sm leading-relaxed text-grey-900">
              Not registered yet? <Link to="/signup" className="font-bold text-grey-700 hover:underline">Create an Account</Link>
            </p>
          </form>

          <div className="flex items-center mb-6"> {/* Increased bottom margin */}
              <hr className="h-0 border-b border-solid border-yellow-300 grow" />
              <p className="mx-4 text-grey-600">or</p>
              <hr className="h-0 border-b border-solid border-yellow-300 grow" />
            </div>

            <div className="flex items-center mb-3">
                <div className="flex flex-col w-full h-full text-center bg-white rounded-2xl">
                    <button 
                      onClick={() => handleGoogleSignIn()}
                      className="flex items-center justify-center w-full py-4 text-sm font-medium transition duration-300 rounded-2xl text-grey-900 bg-grey-300 hover:bg-grey-400 focus:ring-4 focus:ring-grey-300"
                    >
                      <img className="h-5 mr-2" src="https://raw.githubusercontent.com/Loopple/loopple-public-assets/main/motion-tailwind/img/logos/logo-google.png" alt="" />
                      Sign in with Google
                    </button>
                </div>
            </div>

        </div>
      </div>
    </div>
  );
};

export default SignIn;