import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import { FilePlus2Icon } from 'lucide-react';
import { supabase } from '../lib/supabase';
import defaultImage from '../assets/default-image.svg';
import { useAuth } from '../contexts/AuthContext';
import { formatDistanceToNow } from 'date-fns';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [projects, setProjects] = useState([]);
  const [editingProjectId, setEditingProjectId] = useState(null);

  useEffect(() => {
    if (user) {
      fetchProjects();
    }
  }, [user]);

  const fetchProjects = async () => {
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleCreateProject = async () => {
    try {
      const { data, error } = await supabase
        .from('projects')
        .insert([
          { name: 'New Project', user_id: user.id }
        ])
        .select()
        .single();

      if (error) throw error;

      await fetchProjects();
      navigate(`/editor/${data.id}`);
    } catch (error) {
      console.error('Error creating project:', error);
      alert('Failed to create project. Please try again.');
    }
  };

  const handleEditProjectName = async (projectId, newName) => {
    try {
      const { error } = await supabase
        .from('projects')
        .update({ name: newName })
        .eq('id', projectId);

      if (error) throw error;

      setProjects(projects.map(p => p.id === projectId ? {...p, name: newName} : p));
      setEditingProjectId(null);
    } catch (error) {
      console.error('Error updating project name:', error);
      alert('Failed to update project name. Please try again.');
    }
  };

  const formatProjectTime = (time) => {
    return formatDistanceToNow(new Date(time), { addSuffix: true });
  };

  const handleOpenProject = (projectId) => {
    console.log('Opening project with ID:', projectId);
    navigate(`/editor/${projectId}`);
  };

  return (
    <DashboardLayout pageTitle="Projects">
      <div className="w-full max-w-22xl pt-1 sm:pt-4">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-regular text-zinc-400">All</h3>
          <button 
            onClick={handleCreateProject}
            className="bg-yellow-400 hover:bg-black text-black hover:text-yellow-400 font-medium py-2 px-4 rounded-md flex items-center transition-all duration-200 ease-in-out whitespace-nowrap h-10"
          >
            <FilePlus2Icon className="w-5 h-5 transition-colors duration-200 ease-in-out" />
            <span className="hidden lg:inline ml-2">Create project</span>
          </button>
        </div>

        <section className="mb-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {projects.length > 0 ? (
              projects.map((project) => (
                <div key={project.id} className="bg-zinc-900 border border-zinc-700 rounded-lg overflow-hidden">
                  <div className="w-full h-40 p-4 rounded-t-lg overflow-hidden"> {/* Added padding and rounding here */}
                    <img 
                      src={project.thumbnail || defaultImage} 
                      alt={project.name} 
                      className="w-full h-full object-cover rounded-lg" // Adjusted classes here
                    />
                  </div>
                  <div className="p-4">
                    {editingProjectId === project.id ? (
                      <input
                        type="text"
                        value={project.name}
                        onChange={(e) => setProjects(projects.map(p => p.id === project.id ? {...p, name: e.target.value} : p))}
                        onBlur={() => handleEditProjectName(project.id, project.name)}
                        onKeyPress={(e) => e.key === 'Enter' && handleEditProjectName(project.id, project.name)}
                        className="text-lg font-regular bg-zinc-700 text-white px-2 py-1 w-full outline-none rounded-md mb-2"
                        autoFocus
                      />
                    ) : (
                      <h4 
                        className="text-lg font-regular text-white cursor-pointer"
                        onDoubleClick={() => setEditingProjectId(project.id)}
                      >
                        {project.name}
                      </h4>
                    )}
                    <p className="text-sm text-gray-400">
                      Created {formatProjectTime(project.created_at)}
                    </p>
                    <button 
                      onClick={() => handleOpenProject(project.id)}
                      className="mt-2 bg-zinc-800 text-zinc-200 px-3 py-1 rounded hover:bg-zinc-600"
                    >
                      Open Project
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No projects found. Create a new project to get started!</p>
            )}
          </div>
        </section>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;