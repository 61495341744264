import React, { useState, useEffect } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { Bell, Globe, Lock, Moon, Sun } from "lucide-react";
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

const Settings = () => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState(true);
  const [language, setLanguage] = useState('en');
  const [darkMode, setDarkMode] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (user) {
      fetchUserSettings();
    }
  }, [user]);

  const fetchUserSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      setNotifications(data.notifications);
      setLanguage(data.language);
      setDarkMode(data.dark_mode);
      setTwoFactor(data.two_factor);
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  const handleToggle = (setter) => {
    setter((prev) => !prev);
    setIsChanged(true);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    setIsChanged(true);
  };

  const handleSaveSettings = async () => {
    try {
      const { error } = await supabase
        .from('user_settings')
        .upsert({
          user_id: user.id,
          notifications,
          language,
          dark_mode: darkMode,
          two_factor: twoFactor
        });

      if (error) throw error;

      setIsChanged(false);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Failed to save settings. Please try again.');
    }
  };

  return (
    <DashboardLayout pageTitle="Settings">
      <div className="w-full max-w-2xl pt-1 sm:pt-6">
        <div className="bg-zinc-900 rounded-lg border border-zinc-700 p-6 mb-6">
          <h2 className="text-lg font-regular text-zinc-400 whitespace-nowrap mb-4">User Settings</h2>
          
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Bell className="w-5 h-5 text-zinc-400 mr-3" />
                <span className="text-zinc-300">Notifications</span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" checked={notifications} onChange={() => handleToggle(setNotifications)} />
                <div className="w-11 h-6 bg-zinc-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500"></div>
              </label>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Globe className="w-5 h-5 text-zinc-400 mr-3" />
                <span className="text-zinc-300">Language</span>
              </div>
              <select 
                value={language} 
                onChange={handleLanguageChange}
                className="bg-zinc-800 text-zinc-300 rounded-md px-3 py-2 focus:outline-none"
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
              </select>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {darkMode ? <Moon className="w-5 h-5 text-zinc-400 mr-3" /> : <Sun className="w-5 h-5 text-zinc-400 mr-3" />}
                <span className="text-zinc-300">Dark Mode</span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" checked={darkMode} onChange={() => handleToggle(setDarkMode)} />
                <div className="w-11 h-6 bg-zinc-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500"></div>
              </label>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Lock className="w-5 h-5 text-zinc-400 mr-3" />
                <span className="text-zinc-300">Two-Factor Authentication</span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" checked={twoFactor} onChange={() => handleToggle(setTwoFactor)} />
                <div className="w-11 h-6 bg-zinc-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500"></div>
              </label>
            </div>
          </div>

          <div className="mt-8">
            <button 
              onClick={handleSaveSettings}
              disabled={!isChanged}
              className={`font-bold py-3 px-6 rounded ${
                isChanged 
                  ? 'bg-green-500 hover:bg-green-700 text-white' 
                  : 'bg-zinc-500 opacity-50 cursor-not-allowed text-white'
              }`}
            >
              Save Settings
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Settings;