import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

const ProfileInfo = ({ compact = false, hideHeader = true }) => {
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchProfile() {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) throw error;
        setProfile(data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Could not fetch profile');
      } finally {
        setLoading(false);
      }
    }

    if (user) fetchProfile();
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (compact) {
    return (
      <>
        <p className="text-sm font-medium text-zinc-400 group-hover:text-white transition-colors duration-200">
          {profile?.full_name || user?.user_metadata?.full_name || user?.email || 'No Name'}
        </p>
        <p className="text-xs text-zinc-500 group-hover:text-zinc-300 transition-colors duration-200">
          {profile?.email || user?.email || 'No Email'}
        </p>
      </>
    );
  }

  return (
    <div>
      {!hideHeader && <h2>Profile</h2>}
      {profile && (
        <>
          <p>Full Name: {profile.full_name}</p>
          <p>Username: {profile.username}</p>
          <p>Email: {profile.email || user.email}</p>
          {/* Add more profile fields as needed */}
        </>
      )}
    </div>
  );
};

export default ProfileInfo;
