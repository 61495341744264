import React, { useState, useRef, useEffect, useCallback } from 'react';
import CommentBox from './TaskCommentBox';
import { useUser } from '../contexts/UserContext';

const HotspotIconWithBox = ({ 
  hotspotId, 
  hotspotNumber, 
  hotspotType,
  comments, 
  onAddComment,
  onDragStart, 
  setActiveHotspot, 
  isDragging,
  setIsInteractingWithCommentBox,
  onDeleteHotspot,
  onEditComment,
  onDeleteComment,
  openFlyover,
  onToggleFlyover,
  isInViewport = false // New prop to differentiate between viewport and drawer
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [priority, setPriority] = useState('normal');
  const containerRef = useRef(null);
  const isDraggingRef = useRef(false);
  const dragStartTimeRef = useRef(0);

  const {user} = useUser();

  const getIconColor = useCallback(() => {
    return '#000000'; // Default color for hotspots
  }, []);

  const handlePriorityChange = (newPriority) => {
    setPriority(newPriority);
    // If you need to update this in a parent component or backend, add that logic here
  };

  const getIconComponent = useCallback(() => {
    const IconComponent = hotspotType.icon;
    return <IconComponent className="w-8 h-8 text-white" />;
  }, [hotspotType]);

  const toggleComments = (e) => {
   e.stopPropagation();
   console.log('here')
    if (!isDraggingRef.current) {
      setIsOpen(!isOpen);
      setActiveHotspot();
    }
  };

  const closeComments = (e) => {
    setIsOpen(!isOpen);
    setActiveHotspot();
  };

  const handleMouseDown = (e) => {
    if (e.target.closest('.comment-box-wrapper')) {
      setIsInteractingWithCommentBox(true);
      return;
    }
    isDraggingRef.current = false;
    dragStartTimeRef.current = Date.now();
    onDragStart(e);
    setActiveHotspot();

    e.stopPropagation();
  };

  const handleMouseUp = () => {
    const dragDuration = Date.now() - dragStartTimeRef.current;
    if (dragDuration > 200) {
      isDraggingRef.current = true;
    }
    setTimeout(() => {
      isDraggingRef.current = false;
    }, 50);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleCommentBoxClick = (e) => {
    e.stopPropagation();
    setActiveHotspot();
  };

  const handleAddComment = useCallback((commentData) => {
    onAddComment(hotspotId, commentData);
  }, [hotspotId, onAddComment]);

  const handleDeleteHotspot = () => {
    console.log('Deleting hotspot from HotspotWithComments');
    onDeleteHotspot();
  };

  console.log('onDeleteHotspot in HotspotWithComments:', onDeleteHotspot);

  const handleTaskIconClick = (e) => {
    e.stopPropagation();
    // Add your logic here, e.g., open a task creation modal
    console.log('Task icon clicked');
  };

  console.log("Comments received in HotspotWithComments:", comments);

  return (
    <div 
      ref={containerRef}
      className="hotspot-container relative select-none"
      style={{ pointerEvents: isDragging ? 'none' : 'auto' }}
      onMouseDown={handleMouseDown}
    >
      <div className={`absolute ${isInViewport ? 'top-[-28px] left-[-16px]' : 'top-[-20px] left-[-20px]'}`}>
        <div className="relative flex items-center justify-center">
          {/* Black box - only show when not open */}
          {!isOpen && (
            <div className="absolute right-0 rounded-md py-1 pr-3 pl-10 flex items-center justify-end shadow-md border border-zinc-700 h-7 min-w-[26px] w-[calc(100%+6px)] left-4 z-0 bg-zinc-900">
              <span className="text-white font-bold text-lg -mr-1 mt-0.5 relative">
                {String(hotspotNumber).padStart(2, '0')}
              </span>
            </div>
          )}
          
          {/* White circle */}
          {isInViewport && (
            <div 
              className="absolute rounded-full bg-white bg-opacity-50 w-[46px] h-[46px] -left-[7px] z-1"
            ></div>
          )}
          
          {/* Icon */}
          <button 
            className="icon-wrapper w-14 h-14 p-0 border-0 bg-transparent cursor-move focus:outline-none relative z-10 hover:cursor-grab active:cursor-grabbing"
            onClick={toggleComments}
            style={{ zIndex: 3 }}
          >
            {getIconComponent()}
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="comment-box-wrapper" onClick={handleCommentBoxClick}>
          <CommentBox
            hotspotId={hotspotId}
            hotspotNumber={hotspotNumber}
            comments={comments}
            onAddComment={handleAddComment}
            onDeleteHotspot={handleDeleteHotspot}
            onEditComment={(hotspotId, commentId, updatedContent) => onEditComment(hotspotId, commentId, updatedContent)}
            onDeleteComment={(commentId) => onDeleteComment(hotspotId, commentId)}
            priority={priority}
            onPriorityChange={handlePriorityChange}
            onToogleCommentBox={closeComments}
            openFlyover={openFlyover}
            onToggleFlyover={onToggleFlyover}
          />
        </div>
      )}
    </div>
  );
};

export default HotspotIconWithBox;
