import { createClient } from '@supabase/supabase-js';

// Regular Supabase client
const supabaseUrl = "https://ryxucindmshrpifolhrk.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ5eHVjaW5kbXNocnBpZm9saHJrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjYyNjg1OTIsImV4cCI6MjA0MTg0NDU5Mn0.KaRDykLCTQMpiBApDY60ZmdF_YYXC3yYVJXnSYzqBMQ";

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Self-hosted Supabase client
console.log('All environment variables:', process.env);

const supabaseUrlSelfHosted = process.env.REACT_APP_SUPABASE_URL_SELF_HOSTED || 'http://localhost:8000';
const supabaseAnonKeySelfHosted = process.env.REACT_APP_SUPABASE_ANON_KEY_SELF_HOSTED || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyAgCiAgICAicm9sZSI6ICJhbm9uIiwKICAgICJpc3MiOiAic3VwYWJhc2UtZGVtbyIsCiAgICAiaWF0IjogMTY0MTc2OTIwMCwKICAgICJleHAiOiAxNzk5NTM1NjAwCn0.dc_X5iR_VP_qT0zsiyj_I_OZ2T9FtRU2BBNWN8Bu4GE';

console.log('Self-hosted Supabase URL:', supabaseUrlSelfHosted);
console.log('Self-hosted Supabase Anon Key exists:', !!supabaseAnonKeySelfHosted);

export const supabaseSelfHosted = createClient(supabaseUrlSelfHosted, supabaseAnonKeySelfHosted);

// Log initialization status (without revealing sensitive information)
console.log('Regular Supabase client initialized:', !!supabase);
console.log('Self-hosted Supabase client initialized:', !!supabaseSelfHosted);
