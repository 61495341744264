import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { SmileIcon, AtSignIcon, ImageIcon, PaperclipIcon, ArrowUpIcon, XIcon, MoreHorizontalIcon, PencilIcon, TrashIcon, BookmarkIcon, LinkIcon, FlagIcon, CheckCircleIcon } from "lucide-react"
import EmojiPicker from 'emoji-picker-react';
import '../EmojiPickerStyles.css';
import { debounce } from 'lodash';
import { supabase } from '../lib/supabase';
import { useUser } from '../contexts/UserContext';

// Custom wrapper for EmojiPicker
const CustomEmojiPicker = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{
      '--epr-emoji-size': '20px',
      '--epr-emoji-padding': '6px',
      width: '300px',
      height: '320px',
    }}>
      <EmojiPicker
        {...props}
        emojiStyle="native"
        searchDisabled
        skinTonesDisabled
        previewConfig={{
          showPreview: false
        }}
        width="100%"
        height="100%"
      />
    </div>
  );
});

const CommentBox = ({ 
  taskId, 
  taskNumber, 
  comments, 
  onAddComment, 
  onDeleteTask, 
  onEditComment,
  onDeleteComment,
  onToogleCommentBox,
  priority,
  onPriorityChange,
  openFlyover,
  onToggleFlyover
}) => {
  const { user } = useUser();
  const [users, setUsers] = useState([]);
  const [newComment, setNewComment] = useState('');

  const [localComments, setLocalComments] = useState([]);

  const [isTyping, setIsTyping] = useState(false);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const newCommentRef = useRef(null);

  const currentCommentRef = useRef(null);

  const cursorPositionRef = useRef(null);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const userListRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [showFlyover, setShowFlyover] = useState(false);
  const flyoverRef = useRef(null);
  const [showFlagFlyover, setShowFlagFlyover] = useState(false);
  const flagFlyoverRef = useRef(null);
  const [menuContent, setMenuContent] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [imageUrls, setImageUrls] = useState([]);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editContent, setEditContent] = useState('');
  const editCommentRef = useRef(null);
  const [showComposer, setShowComposer] = useState({});
  const [editingCommentIndex, setEditingCommentIndex] = useState(null);

  const commentStyle = {
    fontSize: '16px',
    lineHeight: '1.5',
  };

  // State for Overflow 1 (thread-level overflow)
  const [showOverflow1, setShowOverflow1] = useState(false);

  // State for Overflow 2 (comment-level overflow)
  const [openCommentMenuId, setOpenCommentMenuId] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isMentionFocused, setIsMentionFocused] = useState(false);

  const [isEmojiPickerFocused, setIsEmojiPickerFocused] = useState(false);

  const [isFlagFocused, setIsFlagFocused] = useState(false);
  const [isMenuFocused, setIsMenuFocused] = useState(false);

  const getUsers = async () => {
    const { data, error } = await supabase
        .from('profiles')
        .select()

        console.log('user data ', data, 'error ', error)
        if (error != null)
          setUsers(data)
        console.log('user data after ', users)
  }

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting || !newComment.trim()) return;

    setIsSubmitting(true);
    try {
      const commentData = {
        content: newComment.trim(),
        user_id: user.id,
      };
      await onAddComment(commentData);
      setNewComment('');
      newCommentRef.current.innerHTML = ''
      fetchComments();
    } catch (error) {
      console.error('Error submitting comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const setCursorToEnd = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
  };

  const handleInputChange = (e) => {
    const content = e.target.innerHTML || '';
    console.log('handleInputChange content', content)
    if (editingCommentIndex !== null) {
      // If editing an existing comment
      if (typeof onEditComment === 'function') {
        console.log('onEdit handleInputChange')
        onEditComment(editingCommentIndex, content);
      } else {
        console.error('onEditComment is not a function');
        // Optionally, update local state to reflect changes
        const updatedComments = [...comments];
        updatedComments[editingCommentIndex] = content;
        // You might want to add a setComments function if you're managing comments state locally
        // setComments(updatedComments);
      }
    } else {
      // If adding a new comment
      setNewComment(content);
    }
    setIsTyping(content.trim().length > 0);
    setIsInputEmpty(content.trim().length === 0);

    // Update cursor position
    const newPosition = getCaretPosition(e.target);
    setCursorPosition(newPosition);
    cursorPositionRef.current = newPosition;

    // Check if '@' was just typed
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const beforeCursor = range.startContainer.textContent.charAt(range.startOffset - 1);
    if (beforeCursor === '@') {
      setShowUserList(true);
    } else {
      setShowUserList(false);
    }
  };

  const getCaretPosition = (element) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    }
    return 0;
  };

  const toggleUserList = (e) => {
    e.stopPropagation();
    setShowUserList(prev => !prev);
    setIsMentionFocused(prev => !prev);
    
    // Close other flyovers
    setShowEmojiPicker(false);
    setShowFlyover(false);
    setShowFlagFlyover(false);
  };

  const handleSelectUser = useCallback((user) => {
    const mentionText = `@${user.full_name}`;
    
    newCommentRef.current.focus();
    
    const selection = window.getSelection();
    let range = selection.getRangeAt(0);
    
    // Check if the character before the cursor is '@'
    let beforeCursor = '';
    if (range.startOffset > 0) {
      const tempRange = range.cloneRange();
      tempRange.setStart(range.startContainer, range.startOffset - 1);
      beforeCursor = tempRange.toString();
    }
    
    if (beforeCursor === '@') {
      // If it is, move the start of the range one character back
      range.setStart(range.startContainer, range.startOffset - 1);
      // And delete the '@'
      range.deleteContents();
    }

    // Create a span element for the mention
    const mentionSpan = document.createElement('span');
    mentionSpan.className = 'text-blue-500'; // Only change the text color for the mention
    mentionSpan.textContent = mentionText;

    // Insert the mention span
    range.insertNode(mentionSpan);
    
    // Create a text node with a space after the mention
    const spaceNode = document.createTextNode('\u00A0'); // Non-breaking space
    range.setStartAfter(mentionSpan);
    range.insertNode(spaceNode);
    
    // Move the cursor after the space
    range.setStartAfter(spaceNode);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);

    setShowUserList(false);
    setIsTyping(true);
    setNewComment(newCommentRef.current.innerHTML);

    // Update cursor position
    cursorPositionRef.current = getCaretPosition(newCommentRef.current);

    // Trigger an input event to update any other necessary state
    const inputEvent = new Event('input', { bubbles: true, cancelable: true });
    newCommentRef.current.dispatchEvent(inputEvent);
  }, []);

  const setCaretPosition = (element, position) => {
    const range = document.createRange();
    const selection = window.getSelection();
    
    let currentNode = element.firstChild;
    let offset = position;
    
    while (currentNode) {
      if (currentNode.nodeType === Node.TEXT_NODE) {
        if (currentNode.length >= offset) {
          range.setStart(currentNode, offset);
          range.setEnd(currentNode, offset);
          selection.removeAllRanges();
          selection.addRange(range);
          return;
        } else {
          offset -= currentNode.length;
        }
      } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
        if (currentNode.childNodes.length > 0) {
          currentNode = currentNode.firstChild;
          continue;
        } else {
          offset -= currentNode.outerHTML.length;
        }
      }
      
      if (offset < 0) {
        // If we've gone past the desired position, set the caret at the end of the previous node
        range.setStartAfter(currentNode.previousSibling || currentNode);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        return;
      }
      
      currentNode = currentNode.nextSibling;
    }
    
    // If we've reached the end, place the caret at the end of the element
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

useEffect(() => {
  // Fetch users
  const fetchUsers = async () => {
    const { data, error } = await supabase.from('profiles').select();
    if (error) {
      console.error('Error fetching users:', error);
    } else {
      setUsers(data);
    }
  }; // Empty dependency array, this function will not be recreated

  // Handle click outside logic
  const handleClickOutside = (event) => {
    // Close menu if click is outside
    if (menuRef.current && !menuRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target)) {
      setOpenMenuId(null);
    }
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) &&
        !event.target.closest('.emoji-toggle-button')) {
      setShowEmojiPicker(false);
    }

    if (userListRef.current && !userListRef.current.contains(event.target) &&
        !event.target.closest('.at-sign-button') && event.target !== newCommentRef.current) {
      setShowUserList(false);
    }
    if (flyoverRef.current && !flyoverRef.current.contains(event.target)) {
      setShowFlyover(false);
    }
    if (flagFlyoverRef.current && !flagFlyoverRef.current.contains(event.target)) {
      setShowFlagFlyover(false);
    }
    if (!event.target.closest('.more-button')) {
      setOpenMenuId(null);
    }
  };

  // Call the fetch functions
  fetchUsers();
  fetchComments();

  // Generate URLs for selected images
  const urls = selectedImages.map(image => URL.createObjectURL(image));
  setImageUrls(urls);

  // Restore the cursor position in the editable content
  if (cursorPosition !== null && newCommentRef.current) {
    const selection = window.getSelection();
    const range = document.createRange();

    let currentNode = newCommentRef.current;
    let currentPos = 0;

    const traverseNodes = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        if (currentPos + node.length >= cursorPosition) {
          range.setStart(node, cursorPosition - currentPos);
          range.setEnd(node, cursorPosition - currentPos);
          return true;
        } else {
          currentPos += node.length;
        }
      } else {
        for (const childNode of node.childNodes) {
          if (traverseNodes(childNode)) {
            return true;
          }
        }
      }
      return false;
    };

    traverseNodes(currentNode);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  // Handle editing a comment
  if (editingCommentIndex !== null && newCommentRef.current) {
    newCommentRef.current.innerHTML = comments[editingCommentIndex];
    setIsInputEmpty(false);
  }

  // Add event listener for clicks outside the component
  

  // Cleanup function
  return () => {
    // Remove the event listener
    

    // Revoke object URLs to avoid memory leaks
    urls.forEach(url => URL.revokeObjectURL(url));
  };

}, [
  newComment, 
  cursorPosition, 
  taskId, 
  editingCommentIndex, 
  comments, 
  selectedImages, 
  menuRef, 
  buttonRef, 
  emojiPickerRef, 
  userListRef, 
  flyoverRef, 
  flagFlyoverRef, 
  newCommentRef
]);

  // Add this useEffect to restore cursor position
  useEffect(() => {
    if (cursorPositionRef.current !== null && newCommentRef.current) {
      setCaretPosition(newCommentRef.current, cursorPositionRef.current);
    }
  }, [newComment]);

  const handleEditClick = useCallback((commentId, content) => {
    // Here you can implement the edit functionality
    // For now, let's just log the action
    console.log(`Editing comment ${commentId}: ${content}`);
  }, []);

  const toggleEmojiPicker = (event) => {
    event.stopPropagation();
    setShowEmojiPicker(prevState => !prevState);
    setShowUserList(false);
    setShowFlyover(false);
    setShowFlagFlyover(false);
    setIsEmojiPickerFocused(prev => !prev); // Add this line
  };

  const insertAtCursor = (node) => {
    const selection = window.getSelection();
    let range;

    if (selection.rangeCount > 0) {
      range = selection.getRangeAt(0);
    } else {
      range = document.createRange();
      range.selectNodeContents(newCommentRef.current);
      range.collapse(false);
    }

    // Ensure the range is within the editable content
    if (!newCommentRef.current.contains(range.commonAncestorContainer)) {
      range.selectNodeContents(newCommentRef.current);
      range.collapse(false);
    }

    range.deleteContents();
    range.insertNode(node);
    
    // Move the cursor to the right of the inserted node
    range.setStartAfter(node);
    range.setEndAfter(node);
    selection.removeAllRanges();
    selection.addRange(range);
    
    newCommentRef.current.focus();
    setNewComment(newCommentRef.current.innerHTML);
    
    // Trigger the input event to update the cursor position
    const inputEvent = new Event('input', { bubbles: true, cancelable: true });
    newCommentRef.current.dispatchEvent(inputEvent);
  };

  const onEmojiClick = (emojiObject) => {
    const emojiSpan = document.createElement('span');
    emojiSpan.textContent = emojiObject.emoji;
    insertAtCursor(emojiSpan);
    setShowEmojiPicker(false);
    setIsTyping(true);
  };

  const debouncedCreateObjectURL = debounce((image, callback) => {
    const url = URL.createObjectURL(image);
    callback(url);
  }, 300);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImages(prevImages => [...prevImages, file]);
      debouncedCreateObjectURL(file, (url) => {
        setImageUrls(prevUrls => [...prevUrls, url]);
      });
    }
  };

  const toggleFlagFlyover = useCallback(() => {
    setShowFlagFlyover(prev => !prev);
    if (showFlagFlyover) {
      setIsFlagFocused(false);
    }
    setShowFlyover(false);
    setIsMenuFocused(false);
  }, [showFlagFlyover]);

  const toggleFlyover = useCallback(() => {
    setShowFlyover(prev => !prev);
    if (showFlyover) {
      setIsMenuFocused(false);
    }
    setShowFlagFlyover(false);
    setIsFlagFocused(false);
  }, [showFlyover]);

  // Add this useEffect hook to handle clicks outside the flyovers
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (flagFlyoverRef.current && !flagFlyoverRef.current.contains(event.target)) {
        setShowFlagFlyover(false);
        setIsFlagFocused(false);
      }
      if (flyoverRef.current && !flyoverRef.current.contains(event.target)) {
        setShowFlyover(false);
        setIsMenuFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add this new effect to handle mutual exclusivity
  useEffect(() => {
    if (showFlyover) {
      setShowFlagFlyover(false);
    }
    if (showFlagFlyover) {
      setShowFlyover(false);
    }
  }, [showFlyover, showFlagFlyover]);

  const handlePriorityChange = useCallback((newPriority) => {
    onPriorityChange(newPriority);
  }, [onPriorityChange]);

  const getFlagColor = useCallback(() => {
    switch (priority) {
      case 'moderate':
        return 'text-orange-400';
      case 'urgent':
        return 'text-red-500';
      case 'completed':
        return 'text-green-500';
      default:
        return 'text-zinc-400';
    }
  }, [priority]);

  const getFlagHoverColor = useCallback(() => {
    switch (priority) {
      case 'moderate':
        return 'hover:text-orange-300';
      case 'urgent':
        return 'hover:text-red-400';
      case 'completed':
        return 'hover:text-green-400';
      default:
        return 'hover:text-white';
    }
  }, [priority]);

  const flagFlyoverContent = useMemo(() => (
    <div 
      className="absolute left-1/2 transform -translate-x-1/2 right-0 mt-2 w-36 bg-zinc-900 rounded-md shadow-lg ring-1 ring-zinc-700 overflow-hidden z-50"
    >
      <div className="p-1" role="menu" aria-orientation="vertical" aria-labelledby="flag-options-menu">
        <button 
          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
          onClick={(e) => {
            e.stopPropagation();
            handlePriorityChange('normal');
          }}
        >
          <FlagIcon className="w-4 h-4 mr-2 flex-shrink-0 text-zinc-400" />
          <span className="truncate">Normal</span>
        </button>
        <button 
          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
          onClick={(e) => {
            e.stopPropagation();
            handlePriorityChange('moderate');
          }}
        >
          <FlagIcon className="w-4 h-4 mr-2 flex-shrink-0 text-orange-400" />
          <span className="truncate">Moderate</span>
        </button>
        <button 
          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
          onClick={(e) => {
            e.stopPropagation();
            handlePriorityChange('urgent');
          }}
        >
          <FlagIcon className="w-4 h-4 mr-2 flex-shrink-0 text-red-500" />
          <span className="truncate">Urgent</span>
        </button>
        <button 
          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
          onClick={(e) => {
            e.stopPropagation();
            handlePriorityChange('completed');
          }}
        >
          <CheckCircleIcon className="w-4 h-4 mr-2 flex-shrink-0 text-green-500" />
          <span className="truncate">Completed</span>
        </button>
      </div>
    </div>
  ), [handlePriorityChange]);

  const handleDeleteThread = useCallback(() => {
    console.log('handleDeleteThread called in CommentBox');
    if (window.confirm('Are you sure you want to delete this thread? This action cannot be undone.')) {
      console.log('Deleting task from CommentBox');
      if (typeof onDeleteTask === 'function') {
        onDeleteTask();
      } else {
        console.error('onDeleteTask is not a function in CommentBox');
      }
    }
  }, [onDeleteTask]);

  const flyoverContent = useMemo(() => (
    <div 
      className="absolute right-0 mt-2 w-42 bg-zinc-900 rounded-md shadow-lg ring-1 ring-zinc-700 overflow-hidden z-50 transform translate-x-1/2"
    >
      <div className="p-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <button 
          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
          onClick={(e) => {
            e.stopPropagation();
            // Add mark as unread functionality here
          }}
        >
          <BookmarkIcon className="w-4 h-4 mr-2 flex-shrink-0" />
          <span className="truncate">Mark as unread</span>
        </button>
        <button 
          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(`${window.location.origin}/task/${taskId}`);
            // You might want to add a toast or some other feedback to indicate the link was copied
          }}
        >
          <LinkIcon className="w-4 h-4 mr-2 flex-shrink-0" />
          <span className="truncate">Copy link</span>
        </button>
        <button 
          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
          onClick={handleDeleteThread}
        >
          <TrashIcon className="w-4 h-4 mr-2 flex-shrink-0" />
          <span className="truncate">Delete thread</span>
        </button>
      </div>
    </div>
  ), [taskId, handleDeleteThread]);

  const PriorityIcon = priority === 'completed' ? CheckCircleIcon : FlagIcon;

  const ImagePreview = ({ image, onRemove, isWide = false }) => {
    const [showClose, setShowClose] = useState(false);

    return (
      <div 
        className={`relative ${isWide ? 'w-full' : 'w-[70px]'} h-[70px] mr-2 mb-2 rounded-lg overflow-hidden`}
        onMouseEnter={() => setShowClose(true)}
        onMouseLeave={() => setShowClose(false)}
      >
        <img 
          src={URL.createObjectURL(image)}
          alt="Preview" 
          className={`w-full h-full object-cover rounded-lg ${isWide ? 'object-cover' : 'object-cover'}`}
        />
        {showClose && (
          <button
            className="absolute top-1 right-1 bg-black bg-opacity-50 rounded-full p-1"
            onClick={onRemove}
          >
            <XIcon className="w-4 h-4 text-white" />
          </button>
        )}
      </div>
    );
  };

  const removeImage = (index) => {
    setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  

  const toggleComposer = useCallback((index) => {
    console.log('im in toggleComposer', index)
    setShowComposer(prev => {
      const newState = { ...prev, [index]: !prev[index] };
      if (newState[index]) {
        setEditingCommentIndex(index);
        setOpenCommentMenuId(null); // Close the overflow menu when opening the composer
      } else {
        setEditingCommentIndex(null);
      }
      return newState;
    });
  }, []);

 

  // Function to toggle Overflow 1
  const toggleOverflow1 = useCallback((e) => {
    e.stopPropagation();
    setShowOverflow1(prev => !prev);
    // Close Overflow 2 if it's open
    setOpenCommentMenuId(null);
  }, []);

  // Function to toggle Overflow 2
  const toggleOverflow2 = useCallback((index, e) => {
    e.stopPropagation();
    setOpenCommentMenuId(prev => prev === index ? null : index);
    // Close Overflow 1 if it's open
    setShowOverflow1(false);
  }, []);

  const handleEditSubmit = async (taskId, commentId, newContent) => {
    
    console.log('im here in handleEditSubmit', commentId, newContent)
    
    onEditComment(taskId, commentId, newContent);



  };

  const handleDeleteComment = async (commentId) => {
    onDeleteComment(commentId);
  };

  const fetchComments = async () => {
    console.log('taskId', taskId)
    const { data, error } = await supabase
      .from('comments')
      .select()
      .eq('task_id', taskId)
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching comments:', error);
    } else {
      setLocalComments(data);
      console.log('local comments', localComments)
    }
  };


  const uploadImage = async (file) => {
    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${taskId}/${fileName}`;

    const { error: uploadError } = await supabase.storage
      .from('comment-images')
      .upload(filePath, file);

    if (uploadError) {
      console.error('Error uploading image:', uploadError);
      return null;
    }

    const { data: { publicUrl }, error: urlError } = supabase.storage
      .from('comment-images')
      .getPublicUrl(filePath);

    if (urlError) {
      console.error('Error getting public URL:', urlError);
      return null;
    }

    return publicUrl;
  };

  // Add a  to fetch users if you haven't already
  

  

  // Add this function to toggle the menu for a specific comment
  const toggleMenu = (commentId) => {
    setOpenMenuId(prevId => prevId === commentId ? null : commentId);
  };

  // Add this new useEffect hook
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userListRef.current && !userListRef.current.contains(event.target) && 
          !event.target.closest('.at-sign-button')) {
        setShowUserList(false);
        setIsMentionFocused(false);
      }
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) && 
          !event.target.closest('.emoji-toggle-button')) {
        setShowEmojiPicker(false);
        setIsEmojiPickerFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  console.log('taskNumber in CommentBox:', taskNumber); // Add this line for debugging

  return (
    <div className="w-full min-w-[320px] bg-zinc-900 text-zinc-100 rounded-lg shadow-xl select-none border border-zinc-700">
      <div className="pt-4 pr-4 pb-0 pl-4 flex justify-between items-center mb-3">
        <h3 className="text-lg font-semibold">
          Task {taskNumber !== undefined ? String(taskNumber).padStart(2, '0') : 'N/A'}
        </h3>
        <div className="flex items-center space-x-2">
          <div ref={flagFlyoverRef} className="relative">
            <button
              className="focus:outline-none p-1.5 flex items-center justify-center w-8 h-8"
              onClick={toggleFlagFlyover}
              onFocus={() => setIsFlagFocused(true)}
              onBlur={() => setIsFlagFocused(false)}
            >
              <PriorityIcon 
                className={`w-5 h-5 ${getFlagColor()} hover:text-white cursor-pointer`}
              />
            </button>
            {showFlagFlyover && flagFlyoverContent}
          </div>
          <div ref={flyoverRef} className="relative">
            <button
              className="focus:outline-none p-1.5 flex items-center justify-center w-8 h-8"
              onClick={toggleFlyover}
              onFocus={() => setIsMenuFocused(true)}
              onBlur={() => !showFlyover && setIsMenuFocused(false)}
            >
              <MoreHorizontalIcon 
                className={`w-5 h-5 ${
                  showFlyover || isMenuFocused
                    ? 'text-white'
                    : 'text-zinc-400 hover:text-white'
                } cursor-pointer`}
              />
            </button>
            {showFlyover && (
              <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-40 bg-zinc-900 rounded-md shadow-lg z-10 border border-zinc-700">
                <div className="p-1">
                  <button className="w-full text-left px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 rounded-sm">
                    Mark as unread
                  </button>
                  <button className="w-full text-left px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 rounded-sm">
                    Copy link
                  </button>
                  <button className="w-full text-left px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 rounded-sm">
                    Delete thread
                  </button>
                </div>
              </div>
            )}
          </div>
          <button
            className="focus:outline-none p-1.5 flex items-center justify-center w-8 h-8"
            onClick={() => {
              console.log('trying to close', onToogleCommentBox);
              onToogleCommentBox();
            }}
          >
            <XIcon className="w-5 h-5 text-zinc-400 hover:text-white cursor-pointer" />
          </button>
        </div>
      </div>
      <div className="border-b border-zinc-700 mb-3"></div>
      <div className="pt-0 pr-4 pb-4 pl-4">
        <div className="space-y-3">
          {localComments.map((comment, index) => (



            <div key={index} className="relative">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 bg-zinc-700 text-zinc-300 rounded-full flex items-center justify-center flex-shrink-0">
                    <span className="text-xs font-semibold">U</span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <p className="text-xs font-semibold text-zinc-200">Name</p>
                    <p className="text-xs text-zinc-400">0 minutes ago</p>
                  </div>
                </div>
                
                <div className="relative">
                  <button 
                    onClick={() => toggleMenu(comment.id)} 
                    className="text-zinc-400 hover:text-zinc-300 more-button"
                  >
                    <MoreHorizontalIcon className="w-5 h-5" />
                  </button>
                  {openMenuId === comment.id && (
                    <div className="absolute right-0 top-full mt-1 w-30 bg-zinc-900 rounded-md shadow-lg z-10 border border-zinc-700 transform translate-x-1/2">
                      <div className="p-1" role="menu" aria-orientation="vertical" aria-labelledby="comment-options-menu">
                        <button 
                          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
                          onClick={(e) => {
                            e.stopPropagation();

                            toggleComposer(index);
                            toggleMenu(comment.id);
                            setIsInputEmpty(true);
                            setNewComment('')
                          }}
                        >
                          <PencilIcon className="w-4 h-4 mr-2 flex-shrink-0" />
                          <div className="truncate">Edit</div>
                        </button>
                        <button 
                          className="flex items-center px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 w-full rounded-sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteComment(comment.id);
                          }}
                        >
                          <TrashIcon className="w-4 h-4 mr-2 flex-shrink-0" />
                          <span className="truncate">Delete</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {(!showComposer[index] || editingCommentIndex !== index) && (
                <div 
                  className="text-sm text-zinc-100 ml-10 mt-2"
                  style={commentStyle}
                  dangerouslySetInnerHTML={{ __html: comment.content }}
                />
              )}

              {showComposer[index] && editingCommentIndex === index && (
                <div className="mt-2 ml-10">
                  <div className="bg-zinc-700 rounded-lg overflow-hidden">
                    <div
                      ref={currentCommentRef}
                      contentEditable
                      className="w-full bg-transparent text-sm text-zinc-100 focus:outline-none leading-6 p-2 min-h-[2.5rem]"
                      dangerouslySetInnerHTML={{ __html: comment.content }}
                      onInput={(e) => {
                        setIsTyping(e.target.textContent.trim().length > 0);
                      }}
                    />
                    {selectedImages.length > 0 && (
                      <div className="p-2 flex flex-wrap">
                        {selectedImages.length === 1 ? (
                          <ImagePreview 
                            key={0} 
                            image={selectedImages[0]} 
                            onRemove={() => removeImage(0)} 
                            isWide={true}
                          />
                        ) : selectedImages.length === 2 ? (
                          selectedImages.map((image, index) => (
                            <ImagePreview 
                              key={index} 
                              image={image} 
                              onRemove={() => removeImage(index)} 
                              isWide={false}
                            />
                          ))
                        ) : (
                          <>
                            {selectedImages.slice(0, 2).map((image, index) => (
                              <ImagePreview 
                                key={index} 
                                image={image} 
                                onRemove={() => removeImage(index)} 
                              />
                            ))}
                            <div className="relative w-[70px] h-[70px] mr-2 mb-2 rounded-lg overflow-hidden bg-zinc-600 flex items-center justify-center">
                              <img 
                                src={URL.createObjectURL(selectedImages[2])}
                                alt="Preview" 
                                className="w-full h-full object-cover opacity-50"
                              />
                              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                <span className="text-white font-bold">+{selectedImages.length - 2}</span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    <div className="border-t border-zinc-800"></div>
                    <div className="p-1.5 flex items-center justify-between">
                      <div className="flex items-center space-x-2">

                        <button 
                          type="button" 
                          className="cursor-pointer p-1 emoji-toggle-button"
                          onClick={toggleEmojiPicker}
                        >
                          <SmileIcon className="w-5 h-5 text-zinc-400 hover:text-zinc-300" />
                        </button>

                        {showEmojiPicker && (
                          <div 
                            ref={emojiPickerRef} 
                            className="absolute bottom-14 left-0 z-10"
                          >
                            <CustomEmojiPicker onEmojiClick={onEmojiClick} />
                          </div>
                        )}
                        <button 
                          type="button" 
                          className="cursor-pointer p-1 at-sign-button"
                          onClick={toggleUserList}
                        >
                          <AtSignIcon className="w-5 h-5 text-zinc-400 hover:text-zinc-300" />
                        </button>
                        <button 
                          type="button" 
                          className="cursor-pointer p-1"
                          onClick={() => fileInputRef.current.click()}
                        >
                          <ImageIcon className="w-5 h-5 text-zinc-400 hover:text-zinc-300" />
                        </button>
                        <input
                          ref={fileInputRef}
                          type="file"
                          onChange={handleFileChange}
                          accept="image/*"
                          className="hidden"
                        />
                      </div>

                      <button 
                        onClick={(e) => {
                          handleEditSubmit(comment.hotspot_id, comment.id, currentCommentRef.current.innerHTML)
                          toggleComposer(index);
                          fetchComments()
                        }}
                        className={`cursor-pointer rounded-full p-1.5 transition-all duration-300 ${
                          isTyping ? 'bg-yellow-500 hover:bg-yellow-400' : 'bg-zinc-600 cursor-not-allowed'
                        }`}
                        disabled={!isTyping}
                      >
                        <ArrowUpIcon className={`w-4 h-4 ${isTyping ? 'text-black' : 'text-zinc-400'}`} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

          ))}
        </div>

        {/* Composer */}
        <div className="flex items-start space-x-2 mt-4">
          <div className="w-8 h-8 bg-zinc-700 text-zinc-300 rounded-full flex items-center justify-center">
            <span className="text-xs font-semibold">U</span>
          </div>
          <form onSubmit={handleSubmit} className="flex-1 relative">
            {/* User list overflow - appears when @ is clicked or typed */}
            {showUserList && (
              <div 
                ref={userListRef} 
                className="absolute gallery-scroll custom-scrollbar bottom-full left-0 mb-1 w-full bg-zinc-900 rounded-md shadow-lg z-10 border border-zinc-700 max-h-[200px] min-h-[160px] overflow-y-auto"
              >
                <div className="p-1">
                  {users.map((user) => (
                    <button 
                      key={user.id}
                      className="w-full text-left px-3 py-2 text-sm text-zinc-200 hover:bg-zinc-700 rounded-sm"
                      onClick={() => {
                        handleSelectUser(user);
                        setShowUserList(false);
                      }}
                    >
                      @{user.full_name}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className="bg-zinc-700 rounded-lg overflow-hidden">
              <div className="relative">
                {isInputEmpty && (
                  <span className="absolute top-2 left-2 text-zinc-500 pointer-events-none">
                    Add comment
                  </span>
                )}
                <div 
                  ref={newCommentRef}
                  contentEditable
                  onInput={handleInputChange}
                  onFocus={() => setIsInputEmpty(false)}
                  onBlur={(e) => setIsInputEmpty(
                    (e.target.innerHTML || '').trim().length === 0    // Default to empty string if undefined
                  )}
                  onKeyDown={(e) => {
                    if (e.key === '@') {
                      setShowUserList(true);
                    }
                  }}
                  className="w-full bg-transparent text-sm text-zinc-100 focus:outline-none leading-6 p-2 min-h-[2.5rem]"
                />
              </div>
              {selectedImages.length > 0 && (
                <div className="p-2 flex flex-wrap">
                  {selectedImages.length === 1 ? (
                    <ImagePreview 
                      key={0} 
                      image={selectedImages[0]} 
                      onRemove={() => removeImage(0)} 
                      isWide={true}
                    />
                  ) : selectedImages.length === 2 ? (
                    selectedImages.map((image, index) => (
                      <ImagePreview 
                        key={index} 
                        image={image} 
                        onRemove={() => removeImage(index)} 
                        isWide={false}
                      />
                    ))
                  ) : (
                    <>
                      {selectedImages.slice(0, 2).map((image, index) => (
                        <ImagePreview 
                          key={index} 
                          image={image} 
                          onRemove={() => removeImage(index)} 
                        />
                      ))}
                      <div className="relative w-[70px] h-[70px] mr-2 mb-2 rounded-lg overflow-hidden bg-zinc-600 flex items-center justify-center">
                        <img 
                          src={URL.createObjectURL(selectedImages[2])}
                          alt="Preview" 
                          className="w-full h-full object-cover opacity-50"
                        />
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                          <span className="text-white font-bold">+{selectedImages.length - 2}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {/* Divider */}
              <div className="border-t border-zinc-800"></div>
              <div className="p-1.5 flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  {/* Emoji Picker Button */}
                  <button 
                    type="button" 
                    className={`cursor-pointer p-1 emoji-toggle-button focus:outline-none ${showEmojiPicker ? 'bg-zinc-700' : ''}`}
                    onClick={toggleEmojiPicker}
                  >
                    <SmileIcon className={`w-5 h-5 ${showEmojiPicker ? 'text-white' : 'text-zinc-400 hover:text-zinc-300'}`} />
                  </button>
                  
                  {/* Emoji Picker Dropdown */}
                  {showEmojiPicker && (
                    <div 
                      ref={emojiPickerRef} 
                      className="absolute bottom-14 left-0 z-10"
                    >
                      <CustomEmojiPicker onEmojiClick={onEmojiClick} />
                    </div>
                  )}
                  
                  {/* User Mention Button */}
                  <button 
                    type="button" 
                    className={`cursor-pointer p-1 at-sign-button focus:outline-none ${showUserList ? 'bg-zinc-700' : ''}`}
                    onClick={toggleUserList}
                  >
                    <AtSignIcon className={`w-5 h-5 ${showUserList ? 'text-white' : 'text-zinc-400 hover:text-zinc-300'}`} />
                  </button>
                  
                  {/* Image Upload Button */}
                  <button 
                    type="button" 
                    className="cursor-pointer p-1"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <ImageIcon className="w-5 h-5 text-zinc-400 hover:text-zinc-300" />
                  </button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    className="hidden"
                  />
                </div>
                
                <button 
                  type="submit" 
                  className={`cursor-pointer rounded-full p-1.5 transition-all duration-300 ${
                    isTyping || selectedImages.length > 0
                      ? 'bg-yellow-500 hover:bg-yellow-400' 
                      : 'bg-zinc-600 cursor-not-allowed'
                  }`}
                  disabled={!isTyping && selectedImages.length === 0}
                >
                  <ArrowUpIcon className={`w-4 h-4 ${isTyping || selectedImages.length > 0 ? 'text-black' : 'text-zinc-400'}`} />
                </button>
              </div>
            </div>
            {selectedFile && (
              <div className="mt-2 text-sm text-zinc-300">
                Selected file: {selectedFile.name}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CommentBox;