import React, { useState, useEffect } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { User, UserPlus, Upload, Trash2, AlertTriangle } from "lucide-react";
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

const Profile = () => {
  const { user } = useAuth();
  const [avatar, setAvatar] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [originalProfile, setOriginalProfile] = useState(null);

  useEffect(() => {
    if (user) {
      fetchProfile();
    }
  }, [user]);

  const fetchProfile = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;

      setOriginalProfile(data);
      const fullName = data.full_name || user.user_metadata?.full_name || '';
      const [firstName, lastName] = fullName.split(' ');
      setFirstName(firstName || '');
      setLastName(lastName || '');
      setEmail(data.email || user.email || '');
      setUsername(data.username || '');
      setPhone(data.phone || '');
      setIsChanged(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const handleAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarDelete = () => {
    setAvatar(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const fullName = `${firstName} ${lastName}`.trim();
      const { error } = await supabase.auth.updateUser({
        email: email,
        data: { full_name: fullName }
      });

      if (error) throw error;

      const { error: profileError } = await supabase
        .from('profiles')
        .update({ full_name: fullName, username: username, phone: phone })
        .eq('id', user.id);

      if (profileError) throw profileError;

      setIsChanged(false);
      fetchProfile();
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    setIsChanged(true);
  };

  const handleCancel = () => {
    const [firstName, lastName] = (originalProfile.full_name || '').split(' ');
    setFirstName(firstName || '');
    setLastName(lastName || '');
    setEmail(originalProfile.email || user.email || '');
    setUsername(originalProfile.username || '');
    setPhone(originalProfile.phone || '');
    setIsChanged(false);
  };

  return (
    <DashboardLayout pageTitle="Profile">
      <div className="w-full max-w-2xl pt-1 sm:pt-6">
        <div className="bg-zinc-900 rounded-lg border border-zinc-700 p-6 mb-6">
          <div className="w-full h-48 bg-zinc-800 rounded-lg flex items-center justify-center relative mb-6">
            <div className="w-36 h-36 rounded-full border-2 border-dashed border-zinc-500 flex items-center justify-center">
              {avatar ? (
                <img src={avatar} alt="Avatar" className="w-32 h-32 rounded-full object-cover" />
              ) : (
                <UserPlus 
                  className="w-20 h-20 mb-3 ml-3 text-zinc-500" 
                  style={{ strokeWidth: 1 }}
                />
              )}
            </div>
            <div className="absolute bottom-2 right-2 flex space-x-2">
              <label htmlFor="avatar-upload" className="cursor-pointer bg-zinc-600 hover:bg-green-600 text-white p-2 rounded-md w-10 h-10 flex items-center justify-center">
                <Upload className="w-5 h-5" />
                <input
                  id="avatar-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarUpload}
                  className="hidden"
                />
              </label>
              {avatar && (
                <button onClick={handleAvatarDelete} className="bg-zinc-600 hover:bg-red-600 text-white p-2 rounded-md w-10 h-10 flex items-center justify-center">
                  <Trash2 className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
          <div className="space-y-6">
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-zinc-400 mb-2">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => handleInputChange(e, setUsername)}
                className="block w-full rounded-md bg-zinc-800 border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-white py-3 px-4"
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-6 sm:space-y-0">
              <div className="w-full sm:w-1/2">
                <label htmlFor="firstName" className="block text-sm font-medium text-zinc-400 mb-2">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => handleInputChange(e, setFirstName)}
                  className="block w-full rounded-md bg-zinc-800 border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-white py-3 px-4"
                />
              </div>
              <div className="w-full sm:w-1/2">
                <label htmlFor="lastName" className="block text-sm font-medium text-zinc-400 mb-2">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => handleInputChange(e, setLastName)}
                  className="block w-full rounded-md bg-zinc-800 border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-white py-3 px-4"
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-6 sm:space-y-0">
              <div className="w-full sm:w-1/2">
                <label htmlFor="email" className="block text-sm font-medium text-zinc-400 mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => handleInputChange(e, setEmail)}
                  className="block w-full rounded-md bg-zinc-800 border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-white py-3 px-4"
                />
              </div>
              <div className="w-full sm:w-1/2">
                <label htmlFor="phone" className="block text-sm font-medium text-zinc-400 mb-2">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => handleInputChange(e, setPhone)}
                  className="block w-full rounded-md bg-zinc-800 border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-white py-3 px-4"
                />
              </div>
            </div>
            <div className="flex justify-start space-x-4 mt-12">
              <button 
                type="button"
                onClick={handleCancel}
                disabled={!isChanged}
                className={`font-bold py-3 px-6 rounded ${
                  isChanged 
                    ? 'bg-zinc-800 hover:bg-zinc-700 text-white' 
                    : 'bg-zinc-800 opacity-50 cursor-not-allowed text-white'
                }`}
              >
                Cancel
              </button>
              <button 
                onClick={handleSubmit}
                disabled={!isChanged}
                className={`font-bold py-3 px-6 rounded ${
                  isChanged 
                    ? 'bg-green-500 hover:bg-green-700 text-white' 
                    : 'bg-zinc-500 opacity-50 cursor-not-allowed text-white'
                }`}
              >
                Update
              </button>
            </div>
          </div>
        </div>

        {/* Delete Account Section */}
        <div className="bg-zinc-900 rounded-lg border border-zinc-700 p-6 mb-6">
          <h2 className="text-sm font-medium text-zinc-400 mb-4">Delete Account</h2>
          <div className="bg-red-200 border-l-4 border-red-500 text-red-700 p-4 mb-4 flex items-center rounded-md" role="alert">
            <AlertTriangle className="h-5 w-5 mr-2" />
            <p className="font-bold">Proceed with caution</p>
          </div>
          <p className="text-zinc-300 mb-4">
            Make sure you have taken backup of your account in case you ever need to get access to your data. We will completely wipe your data. There is no way to access your account after this action.
          </p>
          <button 
            onClick={() => {/* Add delete account logic here */}}
            className="text-red-500 hover:text-red-700 font-medium underline"
          >
            Continue with deletion
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Profile;