import React from 'react';
import { ChevronUpIcon, ChevronDownIcon, CircleDotIcon } from 'lucide-react';

// Import SVG components
import { ReactComponent as AntennaSvg } from '../assets/icons/Antenna.svg';
import { ReactComponent as AVWiresOutletSvg } from '../assets/icons/AV_Wires_Outlet.svg';
import { ReactComponent as CarbonMonoxideDetectorSvg } from '../assets/icons/Carbon_Monoxide_Detector.svg';
import { ReactComponent as CeilingOutletSvg } from '../assets/icons/Ceiling_Outlet.svg';
import { ReactComponent as DoorbellSvg } from '../assets/icons/Doorbell.svg';
import { ReactComponent as FloodDetectorSvg } from '../assets/icons/Flood_Detector.svg';
import { ReactComponent as GasDetectorSvg } from '../assets/icons/Gas_Detector.svg';
import { ReactComponent as HeatDetectorSvg } from '../assets/icons/Heat_Detector.svg';
import { ReactComponent as OutletSvg } from '../assets/icons/Outlet.svg';
import { ReactComponent as PhoneJackOutletSvg } from '../assets/icons/Phone_Jack_Outlet.svg';
import { ReactComponent as RefrigerantFanCoilsSvg } from '../assets/icons/Refrigerant_Fan_Coils.svg';
import { ReactComponent as SmokeDetectorSvg } from '../assets/icons/Smoke_Detector.svg';
import { ReactComponent as SprinklerHeadOutletSvg } from '../assets/icons/Sprinkler_Head_Outlet.svg';
import { ReactComponent as StandardLightSvg } from '../assets/icons/Standard_Light.svg';
import { ReactComponent as StandardOutletSvg } from '../assets/icons/Standard_Outlet.svg';
import { ReactComponent as StairLightsSvg } from '../assets/icons/Stair_Lights.svg';
import { ReactComponent as SwitchSvg } from '../assets/icons/Switch.svg';
import { ReactComponent as TVHookUpSvg } from '../assets/icons/TV_Hook_Up.svg';
import { ReactComponent as VentSvg } from '../assets/icons/Vent.svg';
import { ReactComponent as DrainSvg } from '../assets/icons/Drain.svg';
import { ReactComponent as DomesticalColdWaterSvg } from '../assets/icons/Domestical_Cold_Water.svg';
import { ReactComponent as DomesticalHotWaterSvg } from '../assets/icons/Domestical_Hot_Water.svg';
import { ReactComponent as GreyWaterSvg } from '../assets/icons/Grey_Water.svg';
import { ReactComponent as OverflowLineSvg } from '../assets/icons/Overflow_Line.svg';
import { ReactComponent as ShowerHeadSvg } from '../assets/icons/Shower_Head.svg';
import { ReactComponent as ShowerPlateSvg } from '../assets/icons/Shower_Plate.svg';
import { ReactComponent as SewerSvg } from '../assets/icons/Sewer.svg';
import { ReactComponent as SprinklerPipeSvg } from '../assets/icons/Sprinkler_Pipe.svg';
import { ReactComponent as StormDrainSvg } from '../assets/icons/Storm_Drain.svg';
import { ReactComponent as ToiletSvg } from '../assets/icons/Toilet.svg';
import { ReactComponent as ValveFaucetSvg } from '../assets/icons/Valve_Faucet.svg';
import { ReactComponent as VacuumInletSvg } from '../assets/icons/Vacuum_Inlet.svg';
import { ReactComponent as VacuumPipeSvg } from '../assets/icons/Vacuum_Pipe.svg';
import { ReactComponent as VacuumPowerUnitSvg } from '../assets/icons/Vacuum_Power_Unit.svg';
import { ReactComponent as WaterSvg } from '../assets/icons/Water.svg';
import { ReactComponent as AirSvg } from '../assets/icons/Air.svg';
import { ReactComponent as HVACLinearReturnDiffuserSvg } from '../assets/icons/HVAC_Linear_Return_Diffuser.svg';
import { ReactComponent as HVACLinearSupplyDiffuserSvg } from '../assets/icons/HVAC_Linear_Supply_Diffuser.svg';
import { ReactComponent as HVACRoundReturnDiffuserSvg } from '../assets/icons/HVAC_Return_Diffuser.svg';
import { ReactComponent as HVACRoundSupplyDiffuserSvg } from '../assets/icons/HVAC_Supply_Diffuser.svg';
import { ReactComponent as RadiantHeatConduitsSvg } from '../assets/icons/Radiant_Heat_Conduits.svg';
import { ReactComponent as RadiantManifoldSvg } from '../assets/icons/Radiant_Manifold.svg';
import { ReactComponent as RefrigerantLinesSvg } from '../assets/icons/Refrigerant_Lines.svg';
import { ReactComponent as StraightDuctSvg } from '../assets/icons/Straight_Duct.svg';
import { ReactComponent as EEStairsSvg } from '../assets/icons/EE_Stairs.svg';
import { ReactComponent as FireplaceSvg } from '../assets/icons/Fireplace.svg';
import { ReactComponent as ScaleSplitFaceSvg } from '../assets/icons/Scale_Split_Face.svg';
import { ReactComponent as StairsSvg } from '../assets/icons/Stairs.svg';
import { ReactComponent as WindowSvg } from '../assets/icons/Window.svg';

const HotspotsMenuDrawer = ({ isOpen, onClose, onSelectHotspot }) => {
  const items = [
    // Electrical section
    { text: 'Electrical', isHeader: true },
    { text: 'Antenna', icon: AntennaSvg },
    { text: 'AV Wires Outlet', icon: AVWiresOutletSvg },
    { text: 'Carbon Monoxide Detector', icon: CarbonMonoxideDetectorSvg },
    { text: 'Ceiling Outlet', icon: CeilingOutletSvg },
    { text: 'Doorbell', icon: DoorbellSvg },
    { text: 'Flood Detector', icon: FloodDetectorSvg },
    { text: 'Gas Detector', icon: GasDetectorSvg },
    { text: 'Heat Detector', icon: HeatDetectorSvg },
    { text: 'Outlet', icon: OutletSvg },
    { text: 'Phone Jack Outlet', icon: PhoneJackOutletSvg },
    { text: 'Refrigerant Fan Coils', icon: RefrigerantFanCoilsSvg },
    { text: 'Smoke Detector', icon: SmokeDetectorSvg },
    { text: 'Sprinkler Head Outlet', icon: SprinklerHeadOutletSvg },
    { text: 'Stair Lights', icon: StairLightsSvg },
    { text: 'Standard Light', icon: StandardLightSvg },
    { text: 'Standard Outlet', icon: StandardOutletSvg },
    { text: 'Switch', icon: SwitchSvg },
    { text: 'TV Hook Up', icon: TVHookUpSvg },
    { text: 'Vacuum Inlet', icon: VacuumInletSvg },
    { text: 'Vent', icon: VentSvg },
    // Plumbing and Water section
    { text: 'Plumbing and Water', isHeader: true },
    { text: 'Drain', icon: DrainSvg },
    { text: 'Domestical Cold Water', icon: DomesticalColdWaterSvg },
    { text: 'Domestical Hot Water', icon: DomesticalHotWaterSvg },
    { text: 'Grey Water', icon: GreyWaterSvg },
    { text: 'Overflow Line', icon: OverflowLineSvg },
    { text: 'Shower Head', icon: ShowerHeadSvg },
    { text: 'Shower Plate', icon: ShowerPlateSvg },
    { text: 'Sewer', icon: SewerSvg },
    { text: 'Sprinkler Pipe', icon: SprinklerPipeSvg },
    { text: 'Storm Drain', icon: StormDrainSvg },
    { text: 'Toilet', icon: ToiletSvg },
    { text: 'Valve Faucet', icon: ValveFaucetSvg },
    { text: 'Vacuum Pipe', icon: VacuumPipeSvg },
    { text: 'Vacuum Power Unit', icon: VacuumPowerUnitSvg },
    { text: 'Water', icon: WaterSvg },
    // HVAC section
    { text: 'HVAC', isHeader: true },
    { text: 'Air', icon: AirSvg },
    { text: 'Linear Return Diffuser', icon: HVACLinearReturnDiffuserSvg },
    { text: 'Linear Supply Diffuser', icon: HVACLinearSupplyDiffuserSvg },
    { text: 'Round Return Diffuser', icon: HVACRoundReturnDiffuserSvg },
    { text: 'Round Supply Diffuser', icon: HVACRoundSupplyDiffuserSvg },
    { text: 'Radiant Heat Conduits', icon: RadiantHeatConduitsSvg },
    { text: 'Radiant Manifold', icon: RadiantManifoldSvg },
    { text: 'Refrigerant Lines', icon: RefrigerantLinesSvg },
    { text: 'Straight Duct', icon: StraightDuctSvg },
    // Structural Elements section
    { text: 'Structural', isHeader: true },
    { text: 'EE Stairs', icon: EEStairsSvg },
    { text: 'Fireplace', icon: FireplaceSvg },
    { text: 'Scale Split Face', icon: ScaleSplitFaceSvg },
    { text: 'Stairs', icon: StairsSvg },
    { text: 'Window', icon: WindowSvg },
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed left-0 right-0 z-10 mt-2 flex px-4">
      <div className="w-full overflow-hidden rounded-lg bg-zinc-900 text-sm leading-6 border border-zinc-700 shadow-lg ring-1 ring-gray-700/5">
        <div className="p-2">
          <div className="flex flex-col flex-wrap gap-x-6
                          h-[220vh] sm:h-[120vh] md:h-[80vh] lg:h-[60vh] xl:h-[70vh] 2xl:h-[50vh] 3xl:h-[20vh]
                          columns-1 sm:columns-1 md:columns-2 lg:columns-3 xl:columns-4 2xl:columns-5 3xl:columns-7">
            {items.map((item, index) => {
              const { text, icon: Icon, isHeader } = item;
              return (
                <div key={index} className="flex flex-col w-42 mb-1">
                  {isHeader && index !== 0 && <div className="border-b border-zinc-700 mb-1 mt-3"></div>}
                  <div 
                    className={`flex items-center p-2 rounded ${!isHeader && 'cursor-pointer hover:bg-zinc-700'}`}
                    onClick={() => !isHeader && onSelectHotspot(item)}
                  >
                    {Icon && <Icon className="h-6 w-6 mr-3 flex-shrink-0 text-white" />}
                    <span 
                      className={`${isHeader ? 'text-zinc-400 font-medium text-lg' : 'text-zinc-400 truncate'} translate-y-[2px]`}
                    >
                      {text}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotspotsMenuDrawer;
