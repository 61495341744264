import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Marzipano from 'marzipano';
import TaskWithComments from '../components/TaskIconWithCommentBox';
import { createRoot } from 'react-dom/client';
import defaultPanoramaUrl from '../assets/default-panorama-grid.jpg';
import { WrenchIcon, HammerIcon, ImageIcon, XIcon, CircleDotIcon, ChevronDownIcon, SaveIcon, ArrowUpCircle, Bell, X, Eye, ChevronUpIcon, ImagePlus } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';  // Add this import at the top of the file
import { supabase } from '../lib/supabase';
import { UserProvider, useUser } from '../contexts/UserContext'; // Update this import
import '../Editor.css'; // Make sure this import is present
import { UploadFile, RetrieveFile } from '../lib/s3client'
import iconSvg from '../assets/icon.svg'; // Add this import at the top of the file
import { ReactComponent as NavigationLinkSvg } from '../assets/icons/Navigation_Link.svg';
import { uploadImageAndUpdateSupabase } from '../services/imageUploadService';
import HotspotsMenuDrawer from '../components/HotspotsMenuDrawer';
import HotspotIconWithBox from '../components/HotspotIconWithBox';



function MarzipanoEditor() {
  const { tourId } = useParams();
  const navigate = useNavigate();
  const viewerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [viewer, setViewer] = useState(null);
  const [currentScene, setCurrentScene] = useState(null);
  const [comments, setComments] = useState({});
  const [activeTaskId, setActiveTaskId] = useState(null);
  const [tasks, setTasks] = useState({});
  const [taskCounter, setTaskCounter] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [viewerReady, setViewerReady] = useState(false);
  const [draggingTask, setDraggingTask] = useState(null);
  const [isInteractingWithCommentBox, setIsInteractingWithCommentBox] = useState(false);
  const [panoramaUploaded, setPanoramaUploaded] = useState(true); // Make false after adjusting the TaskIconWithCommentBox
  const { user } = useUser(); // This should now work
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openPanel, setOpenPanel] = useState(null);
  const [panoramas, setPanoramas] = useState([
    { id: 1, name: 'Panorama 1', src: 'path/to/panorama1.jpg' },
    { id: 2, name: 'Panorama 2', src: 'path/to/panorama2.jpg' },
    // Add more panoramas as needed
  ]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [projectName, setProjectName] = useState('');
  const [tourData, setTourData] = useState({});
  const [navigationLinks, setNavigationLinks] = useState([]);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isHotspotDrawerOpen, setIsHotspotDrawerOpen] = useState(false);

  // Task 1: Toggle panels
  const togglePanel = (panelName) => {
    setOpenPanel(openPanel === panelName ? null : panelName);
  };

  // Task 2: Check user authentication
  useEffect(() => {
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        // Redirect to login page or show login modal
        console.log('User not authenticated. Please log in.');
        navigate('/login'); // Uncomment this line if you want to redirect to a login page
      }
    };

    checkAuth();
  }, []);

  // Task 3: Fetch project data and panoramas
  useEffect(() => {
    console.log('MarzipanoEditor: tourId changed:', tourId);
    if (tourId) {
      fetchProjectData();
      fetchPanoramas();
    } else {
      console.log('No tourId provided, setting default project name');
      setProjectName('New project');
      setTourData({ name: 'New project' });
      setIsLoading(false);
    }
  }, [tourId]);

  // Task 4: Fetch project data
  const fetchProjectData = async () => {
    console.log('Fetching project data for tourId:', tourId);
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('id', tourId)
        .single();

      if (error) throw error;

      console.log('Fetched project data:', data);

      if (data) {
        setProjectName(data.name);
        setTourData(data);
        console.log('Set project name to:', data.name);
      } else {
        console.error('No project found with this ID');
        setProjectName('Project Not Found');
        setTourData({ name: 'Project Not Found' });
      }
    } catch (error) {
      console.error('Error fetching project data:', error);
      setProjectName('Error Loading Project');
      setTourData({ name: 'Error Loading Project' });
    } finally {
      setIsLoading(false);
    }
  };

  // Task 5: Handle project name changes
  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleProjectNameBlur = () => {
    setIsEditingName(false);
    updateProjectName(projectName);
  };

  const handleProjectNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditingName(false);
      updateProjectName(projectName);
    }
  };

  // Task 6: Update project name in database
  const updateProjectName = async (newName) => {
    try {
      const { error } = await supabase
        .from('projects')
        .update({ name: newName })
        .eq('id', tourId);

      if (error) throw error;

      setTourData(prevData => ({ ...prevData, name: newName }));
      console.log('Updated project name to:', newName);
    } catch (error) {
      console.error('Error updating project name:', error);
      alert('Failed to update project name. Please try again.');
    }
  };

  console.log('Current project name:', projectName);

  // Task 7: Handle interactions with comment box
  const handleIsInteractingWithCommentBox = useCallback((interacting) => {
    setIsInteractingWithCommentBox(interacting);
  }, []);

  // Task 8: Save tour data
  const saveTourData = async (updatedTourData) => {
    if (!updatedTourData || !tourId) {
      console.error('No tour data or tour ID to save');
      alert('No project data to save. Please try again.');
      return;
    }

    try {
      const { error } = await supabase
        .from('tours')
        .update(updatedTourData)
        .eq('id', tourId);

      if (error) throw error;

      setTourData(updatedTourData);
      alert('Project saved successfully');
    } catch (error) {
      console.error('Error saving tour data:', error);
      alert('Failed to save project. Please try again.');
    }
  };

  // Task 9: Initialize Marzipano viewer
  useEffect(() => {
    //console.log("Viewer initialization effect running");
    
    if (viewerRef.current && !viewer) {
      //console.log("Creating new viewer");
      const viewerOpts = {
        controls: { 
          mouseViewMode: 'drag'
        },
        stage: {
          progressive: true,
          preserveDrawingBuffer: true
        }
      };
      const newViewer = new Marzipano.Viewer(viewerRef.current, viewerOpts);
      //console.log("New viewer created:", !!newViewer);
      setViewer(newViewer);
      setViewerReady(true);
     // console.log("Viewer initialized and viewerReady set to true");
    } else {
      console.log("Conditions not met for viewer creation");
    }

    return () => {
      if (viewer) {
        console.log("Cleanup function running");
        viewer.destroy();
        setViewer(null);
        setViewerReady(false);
      }
    };
  }, []);

  // Task 10: Load default panorama
  useEffect(() => {
    if (viewer && viewerReady) {
      console.log("Loading default panorama");
      loadPanorama({ file_name: defaultPanoramaUrl, width: 4000 });
    }
  }, [viewer, viewerReady]);

  // Task 11: Load panorama
  const loadPanorama = useCallback((panorama) => {
    if (!viewer || !viewerReady) {
      console.error("Viewer not initialized or not ready");
      return;
    }

    if (!panorama || !panorama.image_url) {
      console.error("Invalid panorama data:", panorama);
      return;
    }

    console.log("Loading panorama:", panorama);

    // Ensure the image_url doesn't have an extra "https://"
    const correctedImageUrl = panorama.image_url.replace(/^https:\/\/panoramas\.https:\/\//, 'https://');

    const source = Marzipano.ImageUrlSource.fromString(correctedImageUrl, {
      crossOrigin: 'anonymous'
    });
    
    source.addEventListener('error', function(e) {
      console.error('Error loading panorama:', e);
    });

    const geometry = new Marzipano.EquirectGeometry([{ width: 4000 }]);
    const view = new Marzipano.RectilinearView({ yaw: Math.PI });

    try {
      const scene = viewer.createScene({
        source: source,
        geometry: geometry,
        view: view,
        pinFirstLevel: true
      });

      scene.switchTo();
      setCurrentScene(scene);  // Make sure this line is present
      console.log("Panorama loaded successfully");
    } catch (error) {
      console.error("Error creating scene:", error);
    }
  }, [viewer, viewerReady]);

  // Task 12: Create thumbnail for uploaded panorama
  const createThumbnail = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = 100;
          canvas.height = 100;
          ctx.drawImage(img, 0, 0, 100, 100);
          resolve(canvas.toDataURL());
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  // Task 13: Handle file upload
  const handleFileUpload = useCallback(async (event) => {
    console.log("File upload triggered");
    const file = event.target.files[0];
    if (file) {
      console.log("File selected:", file.name);
      try {
        // Create thumbnail
        const thumbnailUrl = await createThumbnail(file);

        // Upload file to S3 and update Supabase
        const { imageUrl, data } = await uploadImageAndUpdateSupabase(file, tourId, 'panoramas');

        // Create new panorama object
        const newPanorama = {
          id: data.id,
          project_id: tourId,
          image_url: imageUrl,
          name: file.name,
          created_at: data.created_at,
          thumbnailUrl: thumbnailUrl
        };

        // Add the new panorama to the state
        setPanoramas(prevPanoramas => [...prevPanoramas, newPanorama]);

        // Load the new panorama
        loadPanorama(newPanorama);

        console.log("Panorama uploaded and loaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
        alert(`Failed to upload panorama: ${error.message}`);
      }
    }
  }, [createThumbnail, tourId, loadPanorama]);

  // Task 14: Add comment to task
  const handleAddComment = useCallback(async (taskId, commentData) => {
    console.log('handleAddComment called with:', { taskId, commentData });
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    try {
      if (!user) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('comments')
        .insert({
          task_id: taskId,
          content: commentData.content,
          user_id: user.id
        })
        .select()
        .single();

      if (error) throw error;

      console.log('Comment added successfully:', data);

      setComments(prevComments => ({
        ...prevComments,
        [taskId]: [...(prevComments[taskId] || []), data]
      }));

      // Force a re-render of the TaskWithComments
      setTasks(prevTasks => ({
        ...prevTasks,
        [taskId]: {
          ...prevTasks[taskId],
          lastCommentAdded: Date.now()
        }
      }));

    } catch (error) {
      console.error("Error adding comment:", error);
      alert('Failed to post comment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  }, [user, isSubmitting]);

  // Task 15: Set active task
  const setActiveTask = useCallback((id) => {
    if (!isDragging) {
      setActiveTaskId(id);
      setTasks(prevTasks => {
        const updatedTasks = { ...prevTasks };
        Object.entries(updatedTasks).forEach(([tId, tData]) => {
          const element = tData.instance.domElement();
          if (element) {
            const newZIndex = tId === id ? '21' : '20';
            element.style.zIndex = newZIndex;
            
            const commentBoxWrapper = element.querySelector('.comment-box-wrapper');
            if (commentBoxWrapper) {
              commentBoxWrapper.style.zIndex = tId === id ? '22' : '21';
            }
            
            updatedTasks[tId] = { ...tData, zIndex: newZIndex };
          }
        });
        return updatedTasks;
      });
    }
  }, [isDragging]);

  // Task 16: Start dragging task
  const startDrag = useCallback((taskId, event) => {
    event.preventDefault();
    setDraggingTask(taskId);
    setIsDragging(true);
    
    // Disable panorama movement when dragging
    if (viewer) {
      viewer.controls().disable();
    }
  }, [viewer]);

  // Task 17: Handle task dragging
  const handleDrag = useCallback((event) => {
    if (draggingTask && currentScene) {
      const view = currentScene.view();
      const taskData = tasks[draggingTask];
      
      if (taskData) {
        const taskInstance = taskData.instance;
        const element = taskInstance.domElement();
        
        // Get the current position of the task
        const currentPosition = view.coordinatesToScreen(taskInstance.position());
        
        // Calculate the new position based on mouse movement
        const newPosition = {
          x: currentPosition.x + event.movementX,
          y: currentPosition.y + event.movementY
        };
        
        // Convert screen coordinates back to yaw/pitch
        const coords = view.screenToCoordinates(newPosition);
        
        // Update the task position
        taskInstance.setPosition(coords);

        if (taskData.type === 'navigationLink') {
          // Update the navigationLinks state
          setNavigationLinks(prevLinks => 
            prevLinks.map(link => 
              link.id === draggingTask 
                ? { ...link, position: coords } 
                : link
            )
          );
        }
      }
    }
  }, [draggingTask, currentScene, tasks, setNavigationLinks]);

  // Task 18: End task dragging
  const endDrag = useCallback(() => {
    setDraggingTask(null);
    setIsDragging(false);
    setDragOffset({ x: 0, y: 0 });
    
    // Re-enable panorama movement after dragging
    if (viewer) {
      viewer.controls().enable();
    }
  }, [viewer]);

  // Task 19: Add event listeners for dragging
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', endDrag);
    } else {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', endDrag);
    }

    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', endDrag);
    };
  }, [isDragging, handleDrag, endDrag]);

  // Task 20: Delete task
  const handleDeleteTask = useCallback(async (taskId) => {
    try {
      if (currentScene && tasks[taskId]) {
        const taskInstance = tasks[taskId].instance;
        currentScene.hotspotContainer().removeHotspot(taskInstance);
      }

      const { error: taskError } = await supabase
        .from('tasks')
        .delete()
        .match({ id: taskId });

      if (taskError) throw taskError;

      setTasks(prevTasks => {
        const newTasks = { ...prevTasks };
        delete newTasks[taskId];
        return newTasks;
      });

      setComments(prevComments => {
        const newComments = { ...prevComments };
        delete newComments[taskId];
        return newComments;
      });

      console.log('Task and associated comments deleted');
    } catch (error) {
      console.error("Error deleting task and comments:", error);
      alert('Failed to delete task and comments. Please try again.');
    }
  }, [currentScene, tasks]);

  // Task 21: Edit comment
  const handleEditComment = useCallback((hotspotId, commentId, newContent) => {
    // Update the comment in your state or database
    console.log(`Editing comment ${commentId} for hotspot ${hotspotId}: ${newContent}`);
    // Implement the actual edit logic here
  }, []);

  // Task 22: Delete comment
  const handleDeleteComment = useCallback((hotspotId, commentId) => {
    // Delete the comment from your state or database
    console.log(`Deleting comment ${commentId} for hotspot ${hotspotId}`);
    // Implement the actual delete logic here
  }, []);

  // Task 23: Add task
  const addTask = useCallback(async () => {
    // Check user authentication
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      console.error('User is not authenticated');
      alert('Please log in to add a task');
      return;
    }

    // Proceed with task creation
    if (!currentScene) {
      console.log("No current scene, cannot add task");
      alert('Please upload a panorama image first.');
      return;
    }

    try {
      const taskId = uuidv4();
      const taskNumber = taskCounter.toString().padStart(2, '0');
      setTaskCounter(prevCounter => prevCounter + 1);

      const hotspotContainer = currentScene.hotspotContainer();
      if (!hotspotContainer) {
        console.error("Hotspot container is undefined");
        alert('Error: Unable to access hotspot container. Please try reloading the panorama.');
        return;
      }

      const viewParameters = currentScene.view().parameters();
      const position = { 
        yaw: viewParameters.yaw,
        pitch: viewParameters.pitch
      };

      // Add task to Supabase
      const { data, error } = await supabase
        .from('tasks')
        .insert([{ 
          id: taskId, 
          number: taskNumber, 
          position: JSON.stringify(position),
          user_id: user.id,  // Include the user_id
          // Add any other required fields here
        }])
        .single();

      if (error) {
        console.error("Supabase error:", error);
        throw new Error(`Supabase error: ${error.message}`);
      }

      const wrapper = document.createElement('div');
      wrapper.className = 'task-wrapper';
      wrapper.style.zIndex = '20';

      const taskInstance = hotspotContainer.createHotspot(wrapper, position);
      
      setTasks(prev => ({
        ...prev,
        [taskId]: { instance: taskInstance, number: taskNumber }
      }));

      const taskWithComments = (
        <UserProvider>
          <TaskWithComments
            taskId={taskId}
            taskNumber={taskNumber}
            comments={comments[taskId] || []}
            onAddComment={handleAddComment}
            onDragStart={(e) => startDrag(taskId, e)}
            setActiveTask={() => setActiveTask(taskId)}
            isDragging={draggingTask === taskId}
            isActive={activeTaskId === taskId}
            setIsInteractingWithCommentBox={handleIsInteractingWithCommentBox}
            onDeleteTask={() => handleDeleteTask(taskId)}
            onEditComment={(commentId, newContent) => handleEditComment(taskId, commentId, newContent)}
            onDeleteComment={(commentId) => handleDeleteComment(taskId, commentId)}
          />
        </UserProvider>
      );

      const root = createRoot(wrapper);
      root.render(taskWithComments);

      setActiveTask(taskId);

      console.log("Task with comments added successfully");
    } catch (error) {
      console.error("Error adding task:", error);
      alert(`An error occurred while adding the task: ${error.message}`);
    }
  }, [currentScene, taskCounter, comments, draggingTask, activeTaskId, startDrag, setActiveTask, handleAddComment, handleIsInteractingWithCommentBox, handleDeleteTask, handleEditComment, handleDeleteComment]);

  // Task 24: Handle close button click
  const handleClose = () => {
    navigate('/');
  };

  // Task 25: Trigger file input for panorama upload
  const triggerFileInput = useCallback(() => {
    console.log("triggerFileInput called");
    if (fileInputRef.current) {
      console.log("Clicking file input");
      fileInputRef.current.click();
    } else {
      console.error("File input reference is not available");
    }
  }, []);

  // Task 26: Handle viewer controls based on interaction state
  useEffect(() => {
    if (viewer) {
      const controls = viewer.controls();
      const viewerElement = viewer.domElement();

      const preventDrag = (e) => {
        if (isInteractingWithCommentBox) {
          e.preventDefault();
          e.stopPropagation();
        }
      };

      const handleMouseUp = () => {
        setIsInteractingWithCommentBox(false);
        controls.enable();
      };

      if (isInteractingWithCommentBox) {
        controls.disable();
        document.addEventListener('mousemove', preventDrag, { capture: true, passive: false });
        document.addEventListener('mouseup', handleMouseUp, { capture: true });
      } else {
        controls.enable();
      }

      return () => {
        document.removeEventListener('mousemove', preventDrag, { capture: true });
        document.removeEventListener('mouseup', handleMouseUp, { capture: true });
      };
    }
  }, [viewer, isInteractingWithCommentBox]);

  // Task 27: Fetch comments
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const { data, error } = await supabase
          .from('comments')
          .select('id, content, task_id, user_id');

        if (error) throw error;

        const commentsByTask = data.reduce((acc, comment) => {
          if (!acc[comment.task_id]) {
            acc[comment.task_id] = [];
          }
          acc[comment.task_id].push({ id: comment.id, content: comment.content });
          return acc;
        }, {});

        setComments(commentsByTask);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, []);

  // Task 28: Select panorama
  const selectPanorama = (panorama) => {
    // Implement the logic to load the selected panorama
    console.log('Selected panorama:', panorama);
    // You might want to call loadPanorama(panorama.src) here
  };

  // Task 29: Fetch panoramas
  const fetchPanoramas = async () => {
    try {
      if (!tourId) {
        console.error('No tourId provided');
        return;
      }

      const { data, error } = await supabase
        .from('panoramas')
        .select('*')
        .eq('project_id', tourId);

      if (error) throw error;
      setPanoramas(data);
    } catch (error) {
      console.error('Error fetching panoramas:', error.message, error);
    }
  };

  // Task 30: Handle click outside panels
  useEffect(() => {
    function handleClickOutside(event) {
      if (openPanel && !event.target.closest('.panel-container')) {
        setOpenPanel(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPanel]);

  // Task 31: Fetch panoramas on tourId change
  useEffect(() => {
    if (tourId) {
      fetchPanoramas();
    }
  }, [tourId]);

  const NavigationLinkHotspot = ({ onClick }) => (
    <button 
      className="hotspot-container w-11 h-11 p-0 border-0 bg-transparent cursor-move focus:outline-none"
      onClick={onClick}
    >
      <NavigationLinkSvg width="44" height="44" />
    </button>
  );

  // Task 32: Add navigation link
  const addNavigationLink = useCallback(() => {
    if (!currentScene) {
      console.error("No current scene to add navigation link to");
      return;
    }

    const view = currentScene.view();
    const center = view.screenToCoordinates({x: window.innerWidth / 2, y: window.innerHeight / 2});

    const newLinkId = uuidv4();
    const newLink = {
      id: newLinkId,
      position: { yaw: center.yaw, pitch: center.pitch },
      targetSceneId: null
    };

    setNavigationLinks(prevLinks => [...prevLinks, newLink]);

    const element = document.createElement('div');
    element.className = 'hotspot-container w-11 h-11 cursor-move';
    element.style.transform = 'translate(-50%, -50%)'; // Center the icon

    const root = createRoot(element);
    root.render(<NavigationLinkSvg width="44" height="43" />);

    const hotspot = currentScene.hotspotContainer().createHotspot(element, { yaw: center.yaw, pitch: center.pitch });

    setTasks(prevTasks => ({
      ...prevTasks,
      [newLinkId]: {
        instance: hotspot,
        type: 'navigationLink',
        data: newLink
      }
    }));

    element.addEventListener('mousedown', (e) => {
      e.preventDefault();
      startDrag(newLinkId, e);
    });

    console.log("Navigation link added:", newLink);
  }, [currentScene, setNavigationLinks, setTasks, startDrag]);

  // Task 33: Log when Marzipano viewer is ready
  useEffect(() => {
    if (viewer && viewerReady) {
      console.log("Marzipano viewer is ready");
      // You can add any initialization code here
    }
  }, [viewer, viewerReady]);

  // Task 34: Log environment variables
  useEffect(() => {
    console.log('Environment variables:', process.env);
  }, []);

  const handleSelectHotspot = useCallback((hotspotItem) => {
    if (!currentScene) {
      console.error("No current scene to add hotspot to");
      return;
    }

    const view = currentScene.view();
    const center = view.screenToCoordinates({x: window.innerWidth / 2, y: window.innerHeight / 2});

    const hotspotId = uuidv4();
    const hotspotNumber = (Object.keys(tasks).length + 1).toString().padStart(2, '0');

    const hotspotContainer = currentScene.hotspotContainer();
    const wrapper = document.createElement('div');
    wrapper.className = 'hotspot-wrapper';

    const hotspotInstance = hotspotContainer.createHotspot(wrapper, { yaw: center.yaw, pitch: center.pitch });

    const root = createRoot(wrapper);
    root.render(
      <UserProvider>
        <HotspotIconWithBox
          hotspotId={hotspotId}
          hotspotNumber={hotspotNumber}
          hotspotType={hotspotItem}
          comments={[]}
          onAddComment={(hotspotId, commentData) => handleAddComment(hotspotId, commentData)}
          onDragStart={(e) => startDrag(hotspotId, e)}
          setActiveHotspot={() => setActiveTask(hotspotId)}
          isDragging={draggingTask === hotspotId}
          setIsInteractingWithCommentBox={handleIsInteractingWithCommentBox}
          onDeleteHotspot={() => handleDeleteHotspot(hotspotId)}
          onEditComment={(hotspotId, commentId, newContent) => handleEditComment(hotspotId, commentId, newContent)}
          onDeleteComment={(commentId) => handleDeleteComment(hotspotId, commentId)}
          isInViewport={true} // Add this line
        />
      </UserProvider>
    );

    setTasks(prevTasks => ({
      ...prevTasks,
      [hotspotId]: {
        instance: hotspotInstance,
        type: 'hotspot',
        data: { id: hotspotId, number: hotspotNumber, type: hotspotItem.text }
      }
    }));

    setIsHotspotDrawerOpen(false);
  }, [currentScene, tasks, startDrag, setActiveTask, handleAddComment, handleIsInteractingWithCommentBox, handleDeleteHotspot, handleEditComment, handleDeleteComment]);

  function handleDeleteHotspot(hotspotId) {
    if (!currentScene) {
      console.error("No current scene to delete hotspot from");
      return;
    }

    // Remove the hotspot from the Marzipano scene
    const hotspotContainer = currentScene.hotspotContainer();
    const hotspot = tasks[hotspotId]?.instance;
    if (hotspot) {
      hotspotContainer.removeHotspot(hotspot);
    }

    // Remove the hotspot from the tasks state
    setTasks(prevTasks => {
      const newTasks = { ...prevTasks };
      delete newTasks[hotspotId];
      return newTasks;
    });

    // If there are any other cleanup tasks (e.g., removing from database), do them here

    console.log(`Hotspot ${hotspotId} deleted`);
  }

  return (
    <div className="w-full h-screen flex flex-col bg-black">
      {/* Add this hidden file input */}
      <input 
        ref={fileInputRef}
        type="file" 
        accept="image/*" 
        onChange={handleFileUpload} 
        style={{ display: 'none' }}
      />

      <div className="flex-1 relative">
        {/* Top bar */}
        <div className="absolute top-4 left-0 right-0 flex justify-between items-center z-30">
          {/* Icon, Divider, and Project name - aligned left with 50px margin */}
          <div className="px-4">
            <div className="bg-zinc-900 px-3 py-2 border border-zinc-700 shadow-lg rounded flex items-center max-w-[240px] h-10">
              <img src={iconSvg} alt="Project Icon" className="h-5 w-5 flex-shrink-0" />
              <div className="w-0.5 h-5 bg-zinc-700 mx-2 flex-shrink-0"></div>
              {isLoading ? (
                <span className="text-zinc-400 truncate text-base">Loading...</span>
              ) : isEditingName ? (
                <input
                  type="text"
                  value={projectName}
                  onChange={handleProjectNameChange}
                  onBlur={handleProjectNameBlur}
                  onKeyDown={handleProjectNameKeyDown}
                  className="bg-zinc-800 text-white px-1 py-0.5 rounded w-full text-base"
                  autoFocus
                />
              ) : (
                <h1
                  className="text-zinc-400 hover:text-white cursor-pointer truncate text-base"
                  onClick={() => setIsEditingName(true)}
                >
                  {projectName}
                </h1>
              )}
            </div>
          </div>

          {/* Group One: Add Panorama, Gallery, Task, Hotspot, Add link, Set view - centered */}
          <div className="flex space-x-0 bg-zinc-900 border border-zinc-700 shadow-lg rounded-md">
            {/* Add Panorama button (icon only) */}
            <div className="h-10">
              <button 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  console.log("Add Panorama button clicked");
                  triggerFileInput();
                }}
                className="bg-zinc-900 text-zinc-400 hover:text-white px-3 rounded h-full w-full flex items-center justify-center"
                title="Add Panorama"
              >
                <ImagePlus className="h-5 w-5" />
              </button>
            </div>

            {/* Gallery button */}
            <div className="relative panel-container h-10">
              <button
                type="button"
                className={`inline-flex items-center justify-between gap-x-1 bg-zinc-900 px-3 rounded h-full w-full group ${
                  openPanel === 'hotspot2' ? 'text-white' : 'text-zinc-400 hover:text-white'
                }`}
                onClick={() => togglePanel('hotspot2')}
              >
                <ImageIcon className="h-5 w-5" />
                {openPanel === 'hotspot2' ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </button>
              
              {/* Gallery drawer */}
              {openPanel === 'hotspot2' && (
                <div className="fixed left-0 right-0 bottom-0 z-10 h-1/3 px-4 pb-4">
                  <div className="h-full mx-auto overflow-hidden bg-zinc-900 text-sm leading-6 shadow-lg rounded-md border border-zinc-700">
                    <div className="h-full p-2 relative">
                      {/* Add panorama button */}
                      <button 
                        onClick={triggerFileInput}
                        className="absolute top-2 right-2 bg-zinc-900 border border-zinc-700 text-zinc-400 hover:text-white px-4 py-2 rounded cursor-pointer text-base flex items-center"
                      >
                        <ImagePlus className="h-5 w-5 mr-2" /> Add panorama
                      </button>
                      <input 
                        ref={fileInputRef}
                        type="file" 
                        accept="image/*" 
                        onChange={handleFileUpload} 
                        style={{ display: 'none' }}
                      />
                      <div className="h-full overflow-y-auto gallery-scroll custom-scrollbar pr-3 pt-16">
                        <div className="flex flex-wrap gap-x-8 gap-y-2 content-start p-2 pl-1">
                          {panoramas.map((panorama) => {
                            // Ensure the thumbnail_url and image_url don't have an extra "https://"
                            const correctedThumbnailUrl = (panorama.thumbnail_url || '').replace(/^https:\/\/panoramas\.https:\/\//, 'https://');
                            const correctedImageUrl = (panorama.image_url || '').replace(/^https:\/\/panoramas\.https:\/\//, 'https://');
                            
                            return (
                              <div 
                                key={panorama.id} 
                                className="w-32 flex-shrink-0 mb-2 cursor-pointer" 
                                onClick={() => loadPanorama(panorama)}
                              >
                                <div className="h-16">
                                  <img 
                                    src={correctedThumbnailUrl || correctedImageUrl || panorama.file_name} 
                                    alt={panorama.file_name} 
                                    className="w-full h-full object-cover rounded-md"
                                    onError={(e) => {
                                      e.target.onerror = null; 
                                      e.target.src = 'path/to/fallback/image.jpg'; // Replace with a path to a default image
                                    }}
                                  />
                                </div>
                                <p className="mt-1 text-xs text-zinc-400 truncate">{panorama.file_name}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Add Task button */}
            <div className="h-10">
              <button 
                onClick={addTask} 
                className={`bg-zinc-900 text-zinc-400 px-3 rounded hover:text-white h-full w-full flex items-center justify-center ${!panoramaUploaded ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!panoramaUploaded}
              >
                <HammerIcon className="h-5 w-5 text-current" />
              </button>
            </div>

            {/* Hotspot button */}
            <div className="relative panel-container h-10">
              <button
                type="button"
                className={`inline-flex items-center justify-between gap-x-1 bg-zinc-900 px-3 rounded h-full w-full group ${
                  isHotspotDrawerOpen ? 'text-white' : 'text-zinc-400 hover:text-white'
                }`}
                onClick={() => setIsHotspotDrawerOpen(!isHotspotDrawerOpen)}
              >
                <CircleDotIcon className="h-5 w-5" />
                {isHotspotDrawerOpen ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </button>
              
              <HotspotsMenuDrawer 
                isOpen={isHotspotDrawerOpen}
                onClose={() => setIsHotspotDrawerOpen(false)}
                onSelectHotspot={handleSelectHotspot}
              />
            </div>

            {/* Add Navigation link button */}
            <div className="h-10">
              <button 
                onClick={addNavigationLink}
                className="bg-zinc-900 text-zinc-400 hover:text-white px-3 rounded h-full w-full flex items-center justify-center"
              >
                <ArrowUpCircle className="h-5 w-5" />
              </button>
            </div>

            {/* Set view button */}
            <div className="h-10">
              <button 
                onClick={() => {/* Add your Set view functionality here */}}
                className="bg-zinc-900 text-zinc-400 hover:text-white px-3 rounded h-full w-full flex items-center justify-center"
              >
                <Eye className="h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Group Two: Save, Notifications, Close - aligned right with 50px margin */}
          <div className="px-4">
          <div className="flex space-x-0 bg-zinc-900 border border-zinc-700 shadow-lg rounded-md">
            {/* Save button */}
            <div className="h-10">
              <button 
                onClick={() => saveTourData({ ...tourData, name: projectName })} 
                className="bg-zinc-900 text-zinc-400 px-3 rounded hover:text-white h-full w-full flex items-center justify-center"
                disabled={!tourId}
              >
                <SaveIcon className="h-5 w-5" />
              </button>
            </div>

            {/* Notifications button */}
            <div className="relative panel-container h-10">
              <button 
                className={`bg-zinc-900 px-3 rounded h-full w-full flex items-center justify-center ${
                  openPanel === 'notifications' ? 'text-white' : 'text-zinc-400 hover:text-white'
                }`}
                onClick={() => togglePanel('notifications')}
              >
                <Bell className="h-5 w-5" />
              </button>
              {openPanel === 'notifications' && (
                <div className="absolute right-0 mt-2 w-80 bg-zinc-900 border border-zinc-700 shadow-lg rounded-md z-10 overflow-hidden">
                  <div className="py-2">
                    <div className="px-4 py-2 font-semibold text-zinc-200 flex justify-between items-center">
                      <span>Notifications</span>
                      <button 
                        onClick={() => setOpenPanel(null)}
                        className="text-zinc-400 hover:text-white"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                    <div className="border-t border-zinc-700"></div>
                    {/* Add your notification items here */}
                    <div className="m-2">
                      <div className="px-2 py-3 text-sm text-zinc-300 hover:bg-zinc-700 cursor-pointer rounded-md">
                        <div className="font-semibold">New comment on your post</div>
                        <div className="text-zinc-400 text-xs mt-1">2 hours ago</div>
                      </div>
                      <div className="px-2 py-3 text-sm text-zinc-300 hover:bg-zinc-700 cursor-pointer rounded-md mt-2">
                        <div className="font-semibold">Your project was approved</div>
                        <div className="text-zinc-400 text-xs mt-1">1 day ago</div>
                      </div>
                      <div className="px-2 py-3 text-sm text-zinc-300 hover:bg-zinc-700 cursor-pointer rounded-md mt-2">
                        <div className="font-semibold">New feature available</div>
                        <div className="text-zinc-400 text-xs mt-1">3 days ago</div>
                      </div>
                    </div>
                    {/* Add more notifications as needed */}
                    <div className="border-t border-zinc-700 mt-2 pt-2 px-4">
                      <button className="text-zinc-400 hover:text-white text-sm">
                        View all notifications
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Close button */}
            <div className="h-10">
              <button 
                onClick={() => navigate('/')} 
                className="bg-zinc-900 text-zinc-400 px-3 rounded hover:text-white h-full w-full flex items-center justify-center"
              >
                <XIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          </div>
        </div>

        {/* Existing content */}
        <div ref={viewerRef} className="absolute inset-0 z-10">
          {!currentScene && (
            <div className="absolute inset-0 flex items-center justify-center text-white text-2xl">
              Loading default panorama...
            </div>
          )}
        </div>
      </div>      
    </div>
  );
};

export default MarzipanoEditor;