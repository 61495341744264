import React, { useState, useEffect } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { CreditCard, Package, CheckCircle, Calendar, Lock } from "lucide-react";
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

const Billing = () => {
  const { user } = useAuth();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [billingInfo, setBillingInfo] = useState(null);
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (user) {
      fetchBillingInfo();
    }
  }, [user]);

  const fetchBillingInfo = async () => {
    try {
      // Fetch current plan and billing info from your database
      const { data, error } = await supabase
        .from('billing')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      setCurrentPlan(data.current_plan);
      setBillingInfo(data.billing_info);
    } catch (error) {
      console.error('Error fetching billing info:', error);
    }
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    setIsChanged(true);
  };

  const handleSubmitCard = async (e) => {
    e.preventDefault();
    try {
      // Here you would typically send this information to your payment processor
      console.log('Submitting card info:', { cardNumber, expiryDate, cvv });
      
      // After processing, update the billingInfo state and reset form
      // This is a placeholder - replace with actual API call and response handling
      setBillingInfo({
        last4: cardNumber.slice(-4),
        expMonth: expiryDate.split('/')[0],
        expYear: expiryDate.split('/')[1],
      });
      
      setCardNumber('');
      setExpiryDate('');
      setCvv('');
      setIsChanged(false);
    } catch (error) {
      console.error('Error updating billing info:', error);
      alert('Failed to update billing information. Please try again.');
    }
  };

  const handleCancel = () => {
    setCardNumber('');
    setExpiryDate('');
    setCvv('');
    setIsChanged(false);
  };

  return (
    <DashboardLayout pageTitle="Billing">
      <div className="w-full max-w-2xl pt-1 sm:pt-6">
        {/* Current Plan Section */}
        <div className="bg-zinc-900 rounded-lg border border-zinc-700 p-6 mb-6">
          <h2 className="text-lg font-regular text-zinc-400 whitespace-nowrap mb-4">Current Plan</h2>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Package className="w-6 h-6 text-green-500 mr-2" />
              <span className="text-lg text-white">{currentPlan || 'Free Plan'}</span>
              <span className="text-zinc-400 ml-2 text-sm">(7 days to expire)</span>
            </div>
            <button 
              onClick={() => {/* Add upgrade logic here */}}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
            >
              Upgrade
            </button>
          </div>
        </div>

        {/* Billing Information Section */}
        <div className="bg-zinc-900 rounded-lg border border-zinc-700 p-6 mb-6">
          <h2 className="text-lg font-regular text-zinc-400 whitespace-nowrap mb-4">Billing Information</h2>
          {billingInfo ? (
            <div>
              <p className="text-zinc-300 mb-2">
                <CreditCard className="inline w-5 h-5 mr-2" />
                Card ending in {billingInfo.last4}
              </p>
              <p className="text-zinc-300 mb-2">Expires: {billingInfo.expMonth}/{billingInfo.expYear}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmitCard} className="space-y-4">
              <div>
                <label htmlFor="cardNumber" className="block text-sm font-medium text-zinc-400 mb-2">Card Number</label>
                <div className="relative">
                  <CreditCard className="absolute left-3 top-1/2 transform -translate-y-1/2 text-zinc-500" />
                  <input
                    type="text"
                    id="cardNumber"
                    value={cardNumber}
                    onChange={(e) => handleInputChange(e, setCardNumber)}
                    placeholder="1234 5678 9012 3456"
                    className="pl-11 block w-full rounded-md bg-zinc-800 border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-white py-3 px-4 placeholder-zinc-500"
                    required
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label htmlFor="expiryDate" className="block text-sm font-medium text-zinc-400 mb-2">Expiry Date</label>
                  <div className="relative">
                    <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-zinc-500" />
                    <input
                      type="text"
                      id="expiryDate"
                      value={expiryDate}
                      onChange={(e) => handleInputChange(e, setExpiryDate)}
                      placeholder="MM/YY"
                      className="pl-11 block w-full rounded-md bg-zinc-800 border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-white py-3 px-4 placeholder-zinc-500"
                      required
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <label htmlFor="cvv" className="block text-sm font-medium text-zinc-400 mb-2">CVV</label>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-zinc-500" />
                    <input
                      type="text"
                      id="cvv"
                      value={cvv}
                      onChange={(e) => handleInputChange(e, setCvv)}
                      placeholder="123"
                      className="pl-11 block w-full rounded-md bg-zinc-800 border-transparent focus:border-transparent focus:ring-0 focus:outline-none text-white py-3 px-4 placeholder-zinc-500 mb-4"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-start space-x-4 mt-12">
                <button 
                  type="button"
                  onClick={handleCancel}
                  disabled={!isChanged}
                  className={`font-bold py-3 px-6 rounded ${
                    isChanged 
                      ? 'bg-zinc-700 hover:bg-zinc-600 text-white' 
                      : 'bg-zinc-700 opacity-50 cursor-not-allowed text-white'
                  }`}
                >
                  Cancel
                </button>
                <button 
                  type="submit"
                  disabled={!isChanged}
                  className={`font-bold py-3 px-6 rounded ${
                    isChanged 
                      ? 'bg-green-500 hover:bg-green-700 text-white' 
                      : 'bg-zinc-500 opacity-50 cursor-not-allowed text-white'
                  }`}
                >
                  Save Card
                </button>
              </div>
            </form>
          )}
        </div>

        {/* Billing History Section */}
        <div className="bg-zinc-900 rounded-lg border border-zinc-700 p-6 mb-6">
          <h2 className="text-lg font-regular text-zinc-400 whitespace-nowrap mb-4">Billing History</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-zinc-700">
              <thead>
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider">
                    Description
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-zinc-400 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-zinc-800 divide-y divide-zinc-700">
                {/* Example rows - replace with actual data */}
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-300">
                    2023-05-01
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-300">
                    Monthly subscription
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-300">
                    $19.99
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      Paid
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-300">
                    2023-04-01
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-300">
                    Monthly subscription
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-zinc-300">
                    $19.99
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      Paid
                    </span>
                  </td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>

        {/* Available Plans Section */}
        <div className="bg-zinc-900 rounded-lg border border-zinc-700 p-6 mb-6">
          <h2 className="text-lg font-regular text-zinc-400 whitespace-nowrap mb-4">Available Plans</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {['Basic', 'Pro', 'Enterprise'].map((plan) => (
              <div key={plan} className="bg-zinc-800 p-4 rounded-lg">
                <h3 className="text-lg font-regular text-zinc-400 whitespace-nowrap mb-2">{plan}</h3>
                <ul className="text-zinc-300 mb-4">
                  <li className="flex items-center">
                    <CheckCircle className="w-4 h-4 mr-2 text-green-500" />
                    Feature 1
                  </li>
                  <li className="flex items-center">
                    <CheckCircle className="w-4 h-4 mr-2 text-green-500" />
                    Feature 2
                  </li>
                  {/* Add more features as needed */}
                </ul>
                <button className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                  {currentPlan === plan ? 'Current Plan' : 'Upgrade'}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Billing;