import AWS from 'aws-sdk';

// Create an S3 client with Hetzner endpoint
const s3 = new AWS.S3({
  endpoint: process.env.REACT_APP_S3_END_POINT,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION
});

export const UploadFile = async (file, bucketName) => {
  const params = {
    Bucket: bucketName,
    Key: file.name, // The file name will be used as the S3 object key
    Body: file,
    ACL: 'public-read',  // Optional: This makes the file public. Adjust based on your needs.
    ContentType: file.type // Ensures the file type (image) is stored correctly
  };

  try {
    const uploadResponse = await s3.upload(params).promise();
    return uploadResponse.Location; // Returns the file URL
  } catch (error) {
    console.error('Error uploading file: ', error);
    throw error;
  }
};

export const RetrieveFile = async (bucketName, key) => {
  const params = {
    Bucket: bucketName,
    Key: key
  };

  try {
    const fileData = await s3.getObject(params).promise();

    // Convert the buffer into a Blob to create a File-like object
    const blob = new Blob([fileData.Body], { type: fileData.ContentType || 'application/octet-stream' });
    const file = new File([blob], key.split('/').pop(), { type: blob.type });

    return file; // Returning the File object
  } catch (error) {
    console.error('Error retrieving file: ', error);
    throw error;
  }
};


