import { useState, useEffect } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom'; // Import useNavigate and Link
import { HomeIcon, User ,UserIcon, CreditCardIcon, SettingsIcon, UsersIcon, PlusIcon, LogOutIcon, SearchIcon, MoreVerticalIcon, XIcon, TrophyIcon, FilePlus2Icon, FileEditIcon, TrashIcon, ShareIcon } from "lucide-react";
import Logo from '../assets/logo.svg'; // Adjust the path as necessary
import Icon from '../assets/icon.svg'; // Add this import
import '../Dashboard.css';  // Import the CSS file
import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { formatDistanceToNow } from 'date-fns';
import ProfileInfo from '../components/ProfileInfo'; // Add this import
// Remove the import of MarzipanoEditor as it's no longer needed here

const defaultImage = "/panoramas/default-image.jpg"; // Path to your default panorama image
const defaultAvatar = "/panoramas/default-avatar.svg"; // Path to your default avatar image

export default function DashboardLayout({ children, pageTitle = "Home" }) { // Add children and pageTitle props
  const [activeMenu, setActiveMenu] = useState(null);
  const navigate = useNavigate(); // Add this line to use navigation
  const { user, logout } = useAuth();  // Use useAuth hook to get user and logout function
  const [activeButton, setActiveButton] = useState('Home');
  const [projects, setProjects] = useState([]);

  // Add this line to get the user's name
  const userName = user?.user_metadata?.full_name || user?.email || 'User';

  useEffect(() => {
    if (user) {  // Only fetch projects if user is logged in
      fetchProjects();
    }
  }, [user]);

  const fetchProjects = async () => {
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', user.id)  // Filter projects by user_id
        .order('updated_at', { ascending: false });

      if (error) throw error;

      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const toggleMenu = (id, event) => {
    if (activeMenu === id) {
      setActiveMenu(null);
    } else {
      setActiveMenu(id);
      // Set the top position of the menu
      const rect = event.currentTarget.getBoundingClientRect();
      const rightOffset = 16; // 1rem (4 * 4px)
      const topPosition = rect.bottom + window.scrollY;
      const rightPosition = window.innerWidth - rect.right + rightOffset;
      
      document.documentElement.style.setProperty('--menu-top', `${topPosition}px`);
      document.documentElement.style.setProperty('--menu-right', `${rightPosition}px`);
    }
  };

  const MenuOverlay = ({ id, children }) => (
    <div className={`fixed mt-0 mr-2 w-35 rounded-lg bg-zinc-600 shadow-lg z-50 ${activeMenu === id ? 'block' : 'hidden'}`}
         style={{
           top: 'var(--menu-top, auto)',
           right: 'var(--menu-right, 1rem)'
         }}>
      <ul className="py-2">
        <li>
          <a href="#" className="flex items-center px-4 py-2 text-sm hover:bg-zinc-500 text-zinc-200">
            <FileEditIcon className="w-4 h-4 mr-2" />
            Edit
          </a>
        </li>
        <li>
          <a href="#" className="flex items-center px-4 py-2 text-sm hover:bg-zinc-500 text-zinc-200">
            <TrashIcon className="w-4 h-4 mr-2" />
            Delete
          </a>
        </li>
        <li>
          <a href="#" className="flex items-center px-4 py-2 text-sm hover:bg-zinc-500 text-zinc-200">
            <ShareIcon className="w-4 h-4 mr-2" />
            Share
          </a>
        </li>
      </ul>
    </div>
  );

  const handleCreateProject = async () => {
    try {
      const { data, error } = await supabase
        .from('projects')
        .insert([
          { name: 'New Project', user_id: user.id }
        ])
        .select()
        .single();

      if (error) throw error;

      // Navigate to the MarzipanoEditor with the new project ID
      navigate(`/editor/${data.id}`);
    } catch (error) {
      console.error('Error creating project:', error);
      alert('Failed to create project. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/signin');
    } catch (error) {
      console.error('Failed to log out', error);
      // Optionally, show an error message to the user
    }
  };

  // Common button style
  const buttonStyle = (isActive) => `
    flex items-center w-full px-2 py-2 text-sm text-left 
    ${isActive ? 'text-white bg-zinc-700' : 'text-zinc-400'} 
    hover:bg-zinc-700 hover:text-white
    focus:bg-zinc-700 focus:text-white focus:outline-none
    rounded-md transition-colors duration-200
  `;

  const menuItems = [
    { name: 'Home', icon: HomeIcon },
    { name: 'Profile', icon: UserIcon },
    { name: 'Billing', icon: CreditCardIcon },
    { name: 'Settings', icon: SettingsIcon },
  ];

  const formatProjectTime = (timestamp) => {
    if (!timestamp) return 'Unknown';
    const date = new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  return (
    <div className="flex h-screen bg-zinc-900 text-white font-axiforma">
      {/* Sidebar */}
      <aside className="w-16 sm:w-64 bg-zinc-900 p-4 flex flex-col border-r border-zinc-700 overflow-visible">
        <div className="flex items-center justify-center sm:justify-start mb-8 mt-5">
          <img 
            src={Logo} 
            alt="Bimbit Logo" 
            className="h-6 ml-2.5 sm:inline hidden"
          />
          <img
            src={Icon}
            alt="Bimbit Icon"
            className="h-6 ml-0.5 sm:hidden inline"
          />
        </div>
        <nav className="space-y-2 flex-grow mt-1">
          <div className="-mx-4 border-b border-zinc-700 mb-4" />
          <NavLink
            to="/dashboard"
            end
            className={({ isActive }) =>
              `flex items-center justify-center sm:justify-start w-full p-2 rounded transition-colors duration-200 ${
                isActive ? 'text-white bg-zinc-700' : 'text-zinc-400 hover:bg-zinc-700 hover:text-white'
              }`
            }
          >
            <HomeIcon className="w-6 h-6 flex-shrink-0" />
            <span className="ml-3 sm:inline hidden">Projects</span>
          </NavLink>
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              `flex items-center justify-center sm:justify-start w-full p-2 rounded transition-colors duration-200 ${
                isActive ? 'text-white bg-zinc-700' : 'text-zinc-400 hover:bg-zinc-700 hover:text-white'
              }`
            }
          >
            <UserIcon className="w-6 h-6 flex-shrink-0" />
            <span className="ml-3 sm:inline hidden">Profile</span>
          </NavLink>
          <NavLink
            to="/billing"
            className={({ isActive }) =>
              `flex items-center justify-center sm:justify-start w-full p-2 rounded transition-colors duration-200 ${
                isActive ? 'text-white bg-zinc-700' : 'text-zinc-400 hover:bg-zinc-700 hover:text-white'
              }`
            }
          >
            <CreditCardIcon className="w-6 h-6 flex-shrink-0" />
            <span className="ml-3 sm:inline hidden">Billing</span>
          </NavLink>
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              `flex items-center justify-center sm:justify-start w-full p-2 rounded transition-colors duration-200 ${
                isActive ? 'text-white bg-zinc-700' : 'text-zinc-400 hover:bg-zinc-700 hover:text-white'
              }`
            }
          >
            <SettingsIcon className="w-6 h-6 flex-shrink-0" />
            <span className="ml-3 sm:inline hidden">Settings</span>
          </NavLink>
          <div className="-mx-4 border-b border-zinc-700 my-2" />
          <a 
            href="#" 
            className="flex items-center justify-center sm:justify-start w-full p-2 text-zinc-400 rounded hover:bg-zinc-700 hover:text-white transition-colors duration-200"
          >
            <UsersIcon className="w-6 h-6 flex-shrink-0" />
            <span className="ml-3 sm:inline hidden">Team</span>
          </a>
          <a 
            href="#" 
            className="flex items-center justify-center sm:justify-start w-full p-2 text-zinc-400 rounded hover:bg-zinc-700 hover:text-white transition-colors duration-200"
          >
            <PlusIcon className="w-6 h-6 flex-shrink-0" />
            <span className="ml-3 sm:inline hidden">Invite users</span>
          </a>
          <a 
            href="#" 
            className="flex items-center justify-center sm:justify-start w-full p-2 text-zinc-400 rounded hover:bg-zinc-700 hover:text-white transition-colors duration-200"
          >
            <PlusIcon className="w-6 h-6 flex-shrink-0" />
            <span className="ml-3 sm:inline hidden">New team</span>
          </a>
          <div className="-mx-4 border-b border-zinc-700 my-2" />
          <button 
            onClick={handleLogout}
            className="flex items-center justify-center sm:justify-start w-full p-2 text-zinc-400 rounded hover:bg-zinc-700 hover:text-white transition-colors duration-200"
          >
            <LogOutIcon className="w-6 h-6 flex-shrink-0" />
            <span className="ml-3 sm:inline hidden">Logout</span>
          </button>
        </nav>
        
        {/* Divider above user profile */}
        <div className="mt-auto -mx-4 border-t border-zinc-700 mb-4" />
        
        {/* User profile section */}
        <div className="flex items-center justify-center sm:justify-start p-2 rounded-lg hover:bg-zinc-700 transition-colors duration-200 group">
          <div className="h-10 w-10 rounded-full flex items-center justify-center">
            <User className="h-7 w-7 text-zinc-400 group-hover:text-white transition-colors duration-200" />
          </div>
          <div className="ml-3 sm:inline hidden">
            <ProfileInfo compact={true} />
          </div>
        </div>
      </aside>

      {/* Main content */}
      <main className="flex-1 overflow-x-hidden overflow-y-auto">
        <div className="px-4 sm:px-6 md:px-10 pt-0">
          <div className="flex flex-wrap items-center justify-between my-7 gap-4">
            <h4 className="text-lg font-regular text-zinc-400 whitespace-nowrap">{pageTitle}</h4>
            
            <div className="flex items-center justify-end">
              {/* Search input */}
              <div className="relative flex items-center">
                <SearchIcon className="w-5 h-5 absolute left-4 text-gray-400 pointer-events-none transition-all duration-200 ease-in-out" />
                <input
                  type="search"
                  placeholder="Search..."
                  className="search-input bg-zinc-800 text-white pl-10 pr-4 py-2 rounded-md border-none focus:ring-0 focus:outline-none appearance-none placeholder-zinc-500 transition-all duration-200 ease-in-out w-10 focus:w-40 lg:w-38 lg:focus:w-56"
                />
              </div>
            </div>
          </div>

          {/* Full-width divider */}
          <div className="border-t border-zinc-700 my-4 -mx-4 sm:-mx-6 md:-mx-10" />

          {/* Render children here */}
          {children}
        </div>
      </main>
    </div>
  );
}