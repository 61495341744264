import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import { default as Profile } from './pages/Profile';  // Make sure this path is correct
import Billing from './pages/Billing';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import MarzipanoEditor from './pages/MarzipanoEditor';
import ProtectedRoute from './components/ProtectedRoute';
import { UserProvider } from './contexts/UserContext'; // Update this import
import Settings from './pages/Settings';
import { useParams } from 'react-router-dom'; // Added import for useParams

// This component will check if the user is authenticated
const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  const params = useParams();
  console.log('PrivateRoute, user:', user, 'params:', params);
  return user ? children : <Navigate to="/signin" replace />;
};

function App() {
  return (
    <UserProvider>
      <GoogleOAuthProvider 
        clientId="591145570439-ot35ivhm3arr40t7tmc43fe5unvvmlj2.apps.googleusercontent.com"
        onScriptLoadError={() => console.error('Google OAuth script failed to load')}
      >
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              {/* Add this route to redirect root to dashboard */}
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route 
                path="/dashboard" 
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/profile" 
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/billing" 
                element={
                  <ProtectedRoute>
                    <Billing />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/editor/:tourId" 
                element={
                  <PrivateRoute>
                    <MarzipanoEditor />
                  </PrivateRoute>
                } 
              />
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </Router>
        </AuthProvider>
      </GoogleOAuthProvider>
    </UserProvider>
  );
}

export default App;
